import { JSX } from "solid-js";

export function SwitchField(props: {
  attr: string;
  model: Record<string, any>;
  truthy: JSX.Element;
  falsy: JSX.Element;
}) {
  return (
    <>
      <label>
        <span>{props.falsy}:</span>{" "}
        <input
          type="radio"
          checked={!props.model[props.attr]}
          onChange={(event) => {
            if (event.target.checked) {
              props.model[props.attr] = false;
            }
          }}
        />{" "}
      </label>
      <label>
        <span>{props.truthy}:</span>{" "}
        <input
          type="radio"
          checked={props.model[props.attr]}
          onChange={(event) => {
            if (event.target.checked) {
              props.model[props.attr] = true;
            }
          }}
        />{" "}
      </label>
    </>
  );
}
