import { ApiConnector } from "../apiConnector";
import { eventTypes } from "../constants";
import { dateTimeISOString } from "../utils";
import { DataAdd } from "./DataAdd";

export class AddEvents extends DataAdd {
  // constants
  eventTypes = eventTypes;

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [];
    this.initFields = ["event_date"];
    this.desc = "Patient's events";
    this.defaultDateTimeName = "Today";
    this.defaultDateTimeFields = ["event_date"];
    this.endpoint = "events";
    this.reloadToTab = "events";
  }

  async initEmpty() {
    this.defaultDateTime = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    var empty = [];
    const temp = {
      event_date: this.defaultDateTime,
      event_type: null,
    };
    empty.push(temp);
    this.addingList = empty;
  }

  addItem() {
    this.addingList.push({
      event_date: this.defaultDateTime,
      event_type: null,
    });
  }

  async postBulk() {
    for (let i = 0; i < this.addingList.length; i++) {
      this.addingList[i].patient_id = this.patient.patientId;
      this.addingList[i].event_timestamp = dateTimeISOString();
      this.addingList[i] = this.customSanitize(this.addingList[i]);
      for (const key in this.addingList[i]) {
        if (
          this.dateTimeFields.includes(key) &&
          this.addingList[i].hasOwnProperty(key)
        ) {
          var datetime = this.addingList[i][key];
          this.addingList[i][key] = dateTimeISOString(datetime);
        }
      }
    }
    // POST one by one
    for (let i = this.addingList.length - 1; i >= 0; i--) {
      try {
        const response = await this.api.post(this.endpoint, this.addingList[i]);
        if (response != null) {
          this.addingList.splice(i, 1);
        }
      } catch (error) {
        console.error("POST error:", error);
      }
    }
    window.navigate(`/patient/${this.patient.patientId}/${this.reloadToTab}`);
    window.location.reload();
  }
}
