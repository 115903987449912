import { JSX } from "solid-js";

export function ChooseField(props: {
  value: JSX.Element;
  onClick: () => void;
}) {
  return (
    <>
      <span>{props.value}</span>
      <br />
      <button type="button" onClick={props.onClick}>
        Choose
      </button>
    </>
  );
}
