import { Show } from "solid-js";
import { medicalOptions } from "../../../constants";
import { AddEcho } from "../../../dataAdd/AddEcho";
import { AddOtherExams } from "../../../dataAdd/AddOtherExams";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellRadio } from "../../uiKit/addTable/CellRadio";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddExamsTable(props: { model: AddOtherExams | AddEcho }) {
  return (
    <AddTable
      model={props.model}
      canAddRows={false}
      setAllDates="datetime"
      columns={[
        {
          header: "Measured at",
          cell: (item) => (
            <CellDate
              attr="measurement_datetime"
              item={item}
              type="datetime-local"
            />
          ),
        },
        {
          header: "Type",
          cell: (item) => (
            <label>
              {item.measurement_type.charAt(0).toUpperCase() +
                item.measurement_type.slice(1)}
            </label>
          ),
        },
        {
          header: "Value",
          cell: (item) => (
            <>
              <Show when={!(item.measurement_type in medicalOptions)}>
                <CellText attr="measurement_value" item={item} width={50} />
              </Show>
              <Show when={item.measurement_type in medicalOptions}>
                <CellRadio
                  attr="measurement_value"
                  item={item}
                  values={medicalOptions[item.measurement_type]}
                />
              </Show>
            </>
          ),
        },
        {
          header: "Unit",
          cell: (item) => <label>{item.measurement_unit}</label>,
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="comment" item={item} />,
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
    />
  );
}
