import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../../../apiConnector";
import { AddEvents } from "../../../dataAdd/AddEvents";
import { App } from "../../../main";
import { Patient } from "../../../patient";
import { CellEditButtons } from "../../uiKit/table/CellEditButtons";
import { CellTextEditable } from "../../uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "../../uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "../../uiKit/table/HeaderSort";
import { Table } from "../../uiKit/table/Table";
import { AddEventsTable } from "../add/AddEventsTable";

export function EventsTable(props: {
  patient: Patient;
  api: ApiConnector;
  app: App;
}) {
  const model = createMemo(() => props.patient.events);

  return (
    <div>
      <h3>Events:</h3>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "events");
        }}
      >
        Add events
      </button>

      <Show when={props.app.addingActive && !props.app.togglingDataAdd}>
        <AddEventsTable model={props.app.dataAdd as AddEvents} />
      </Show>

      <br />
      <br />
      <br />

      <Table
        model={model()}
        columns={[
          {
            header1: "Date",
            header2: <HeaderSort model={model()} attr="event_date" />,
            cell: (item) => item.event_date,
          },
          {
            header1: "Timestamp",
            header2: <HeaderSort model={model()} attr="event_timestamp" />,
            cell: (item) => item.event_timestamp,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="event_type"
                model={model()}
                name="Type"
                values={model().eventTypes}
              />
            ),
            cell: (item) => model().eventTypes[item.event_type],
          },
          {
            header1: "Description",
            cell: (item, index) => (
              <CellTextEditable
                attr="event_description"
                index={index}
                model={model()}
                width={120}
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="event_comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                model={model()}
                index={index}
                onConfirm={(editedField) => {
                  props.patient.updateItem("events", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.amIAdmin && {
            header1: "Delete",
            cell: (item) => (
              <button
                onClick={() => props.patient.deleteItem("events", item.uuid)}
              >
                Delete
              </button>
            ),
          },
        ]}
      />
    </div>
  );
}
