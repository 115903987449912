import { createMemo, Show } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

export function CellTextEditable<T extends object>(props: {
  model: DataModel<T>;
  index: number;
  attr: string;
  onChange?: (editedField: object) => void;
  displayValue?: any;
  clickToEdit?: boolean;
  width?: number;
  type?: "text" | "number";
  disabled?: boolean;
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()) &&
      !props.disabled,
  );
  const width = createMemo(() => {
    if (props.width != null) {
      return `${props.width}px`;
    } else if (props.type === "number") {
      return "30px";
    } else {
      return undefined;
    }
  });

  return (
    <Show
      when={editing()}
      fallback={
        <div
          onClick={() => {
            if (props.clickToEdit) {
              props.model.startEditing(props.index);
            }
          }}
          style={{ cursor: props.clickToEdit ? "pointer" : undefined }}
        >
          {props.displayValue !== undefined
            ? props.displayValue
            : item()[props.attr]}
        </div>
      }
    >
      <input
        type={props.type ?? "text"}
        value={props.model.editedField?.[props.attr] ?? ""}
        onChange={(event) => {
          if (!props.model.editedField) {
            return;
          }
          props.model.editedField[props.attr] = event.target.value;
        }}
        style={{ width: width() }}
      />

      <Show when={editing() && props.clickToEdit}>
        {" "}
        <button
          onClick={() => {
            props.onChange?.(props.model.editedField);
            props.model.stopEditing();
          }}
        >
          {"\u2620"}
        </button>{" "}
        <button
          onClick={() => {
            props.model.stopEditing();
          }}
        >
          X
        </button>
      </Show>
    </Show>
  );
}
