import { eventTypes } from "../constants";
import { DataModel } from "./DataModel";

export class Events extends DataModel {
  // constants
  eventTypes = eventTypes;

  constructor(data) {
    super(data);
    this.sortBy = ["event_date", "event_timestamp"];
    this.sortByNames = ["Date", "Timestamp"];
    this.dateTimeFields = ["event_timestamp"];
    this.dateFields = ["event_date"];
    this.stringFields = ["event_description", "event_comment"];
    this.editable = ["event_description", "event_comment"];
    this.initSort = [true, null];
    this.filteringAttrs = ["event_type"];
    this.canSwitchSortOrder = true;
  }
}
