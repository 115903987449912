import clsx from "clsx";
import { createMemo, createSignal, For, JSX, Show } from "solid-js";
import { ApiConnector } from "../../apiConnector";
import { races, sexes } from "../../constants";
import {
  informedConsent,
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../../enumToText";
import { App, PatientTabId } from "../../main";
import { Patient } from "../../patient";
import { floatRounding } from "../../utils";
import { DateField } from "../uiKit/form/DateField";
import { FormRow } from "../uiKit/form/FormRow";
import { FormRowEditable } from "../uiKit/form/FormRowEditable";
import { RadioField } from "../uiKit/form/RadioField";
import { SelectField } from "../uiKit/form/SelectField";
import { TextField } from "../uiKit/form/TextField";
import { Tab } from "../uiKit/tabs/Tab";
import { Tabs } from "../uiKit/tabs/Tabs";
import { CreateUserForm } from "./CreateUserForm";

export function PatientPage(props: {
  patient: Patient;
  id: number;
  api: ApiConnector;
  app: App;
  children?: JSX.Element;
}) {
  if (props.app.patient?.patientId !== props.id) {
    const tabId = new URL(window.location.href).pathname.split("/")[3];
    props.app.loadPatient(props.id, tabId as PatientTabId);
  }

  const tabId = createMemo(() => props.app.activePatientModal);

  const openTab = (tabId: PatientTabId) => {
    props.app.patientNavigate(props.id, tabId);
  };

  const data = createMemo(() => props.patient?.data);
  const form = createMemo(() => props.patient?.detailsForm);
  const updateField = (editedField: Record<string, any>) => {
    props.patient.updatePatientField(editedField);
  };
  const [openPatSettings, setOpenPatSettings] = createSignal(false);
  const [addingConnUser, setAddingConnUser] = createSignal(false);

  return (
    <div>
      <h2>Patient #{props.id}</h2>

      <Show when={props.app.loadingPatient}>
        <div class="spinner" style="margin: auto">
          <img src="/static/imgs/sq_spin.png" />
        </div>
      </Show>

      <Show when={!props.app.loadingPatient && props.patient.loaded}>
        <div>
          <table class="invisible-table invisible-table-big">
            <FormRowEditable
              form={form()}
              attr="date_of_birth"
              label="Date of birth"
              model={data()}
              viewMode={new Date(data().date_of_birth).toLocaleDateString(
                "sk-SK",
              )}
              editMode={
                <DateField
                  attr="date_of_birth"
                  model={form().editedField}
                  type="date"
                />
              }
              onChange={updateField}
            />

            <FormRowEditable
              form={form()}
              attr="sex"
              label="Sex"
              model={data()}
              viewMode={data().sex}
              editMode={
                <RadioField
                  attr="sex"
                  model={form().editedField}
                  values={sexes}
                />
              }
              onChange={updateField}
            />

            <FormRowEditable
              form={form()}
              attr="race"
              label="Race"
              model={data()}
              viewMode={data().race}
              editMode={
                <SelectField
                  attr="race"
                  model={form().editedField}
                  values={races}
                />
              }
              onChange={updateField}
            />

            <FormRowEditable
              form={form()}
              attr="height"
              label="Height [cm]"
              model={data()}
              viewMode={floatRounding(data().height, 1)}
              editMode={
                <TextField
                  attr="height"
                  model={form().editedField}
                  width={40}
                />
              }
              onChange={updateField}
            />

            <FormRowEditable
              form={form()}
              attr="description"
              label="Description"
              model={data()}
              viewMode={<div class="max-height">{data().description}</div>}
              editMode={
                <TextField
                  attr="description"
                  model={form().editedField}
                  multiline
                />
              }
              onChange={updateField}
            />

            <Show when={props.api.amILevel3}>
              <FormRowEditable
                form={form()}
                attr="admin_note"
                label="Admin note"
                model={data()}
                viewMode={<div class="max-height">{data().admin_note}</div>}
                editMode={
                  <TextField
                    attr="admin_note"
                    model={form().editedField}
                    multiline
                  />
                }
                onChange={updateField}
              />
            </Show>

            <Show when={props.api.amILevel3}>
              <FormRowEditable
                form={form()}
                attr="monitoring_note"
                label="Monitoring note"
                model={data()}
                viewMode={
                  <div class="max-height">{data().monitoring_note}</div>
                }
                editMode={
                  <TextField
                    attr="monitoring_note"
                    model={form().editedField}
                    width={200}
                  />
                }
                onChange={updateField}
              />
            </Show>
          </table>

          <Show when={props.api.amILevel3}>
            <button
              onClick={() => {
                setOpenPatSettings(!openPatSettings());
              }}
            >
              Toggle patient monitoring settings
            </button>

            <Show when={openPatSettings()}>
              <table class="invisible-table invisible-table-big">
                <FormRow label="Name" data={data().name} />

                <FormRow
                  label="Added"
                  data={new Date(data().created_at).toLocaleString("sk-SK")}
                />

                <FormRow label="Added by" data={data().user.username} />

                <FormRow
                  label="Informed consent"
                  data={
                    <div
                      class={
                        props.patient.consentOk() ? "text-green" : "text-red"
                      }
                    >
                      {informedConsent[data().informed_consent]}
                    </div>
                  }
                />

                <Show when={props.patient.isPaperConsent()}>
                  <FormRow
                    label="Change consent"
                    data={
                      <>
                        <Show when={!props.patient.consentOk()}>
                          <button
                            class="text-green"
                            onClick={() => {
                              props.api.approvePaperConsent(data().patient_id);
                            }}
                          >
                            Approve
                          </button>
                        </Show>
                        <Show when={props.patient.consentOk()}>
                          <button
                            class="text-red"
                            onClick={() => {
                              props.api.revokePaperConsent(data().patient_id);
                            }}
                          >
                            Revoke
                          </button>
                        </Show>
                      </>
                    }
                  />
                </Show>

                <Show
                  when={!props.patient.isPaperConsent() && props.api.amIAdmin}
                >
                  <FormRow
                    label="Change consent"
                    data={
                      <>
                        <Show when={!props.patient.consentOk()}>
                          <button
                            class="text-green"
                            onClick={() => {
                              props.api.approveTeleConsent(data().patient_id);
                            }}
                          >
                            Approve
                          </button>
                        </Show>
                        <Show when={props.patient.consentOk()}>
                          <button
                            class="text-red"
                            onClick={() => {
                              props.api.revokeTeleConsent(data().patient_id);
                            }}
                          >
                            Revoke
                          </button>
                        </Show>
                      </>
                    }
                  />
                </Show>

                <FormRowEditable
                  form={form()}
                  attr="realm"
                  label="Realm"
                  model={data()}
                  viewMode={seerlinqRealms[data().realm]}
                  editMode={
                    <SelectField
                      attr="realm"
                      model={form().editedField}
                      values={seerlinqRealms}
                      intValue
                    />
                  }
                  onChange={updateField}
                />

                <FormRowEditable
                  form={form()}
                  attr="patient_study"
                  label="Seerlinq studies"
                  model={data()}
                  viewMode={data()
                    .patient_study.map((st) => seerlinqStudies[st])
                    .join("; ")}
                  editMode={
                    <SelectField
                      attr="patient_study"
                      model={form().editedField}
                      values={seerlinqStudies}
                      intValue
                      multiple
                    />
                  }
                  onChange={updateField}
                />

                <FormRowEditable
                  form={form()}
                  attr="patient_status"
                  label="Patient status"
                  model={data()}
                  viewMode={patientStatuses[data().patient_status]}
                  editMode={
                    <SelectField
                      attr="patient_status"
                      model={form().editedField}
                      values={patientStatuses}
                      intValue
                    />
                  }
                  onChange={updateField}
                />

                <FormRow
                  label="Connected to users"
                  data={data()
                    .connected_users.map((user) => user.username)
                    .join("; ")}
                />

                <FormRow
                  label="Is managed by physicians"
                  data={data()
                    .physicians.map((physician) =>
                      [physician.given_name, physician.surname].join(" "),
                    )
                    .join("; ")}
                />

                <FormRow
                  label={
                    <>
                      Is managed by users in role <strong>physician</strong>
                    </>
                  }
                  data={data()
                    .managing_physicians.map((user) => user.username)
                    .join("; ")}
                />

                <FormRow
                  label="Last measured PPG"
                  data={props.patient.heartCore.last_ppg}
                />

                <For each={props.patient.schedules.paginatedData}>
                  {(schedule) => (
                    <FormRow
                      label="Schedule"
                      data={props.patient.schedules.schedString(schedule)}
                    />
                  )}
                </For>

                <FormRow label="Patient adherence" data="N/A" />
              </table>

              <button
                onClick={() => {
                  setAddingConnUser(!addingConnUser());
                }}
              >
                Create user for this patient
              </button>

              <Show when={addingConnUser()}>
                <CreateUserForm patient={props.patient} />
              </Show>
            </Show>
          </Show>

          <Show when={props.api.amILevel3}>
            <div style="margin: 20px">
              <h3 style="margin-bottom: -5px">Seerlinq HeartCore</h3>
              <div style="margin-bottom: 10px">
                Patient has <strong>{props.patient.numDRIDataPoints()}</strong>{" "}
                DRI measurements.
                <br />
                <small>
                  (Minimum for running the HeartCore is{" "}
                  <span>{props.patient.minDRIHeartCore}</span>)
                </small>
                <br />
                <br />
                HeartCore Report would be generated from{" "}
                <strong>{props.patient.heartCore.reportStart}</strong>
                <br />
                <small>
                  (HeartCore report requires DRIs, heart rates, systolic and
                  diastolic blood pressures, fatigue scores, and shortness of
                  breath;
                  <br />
                  at least <span>{props.patient.minDRIHeartCore}</span>{" "}
                  measurements of each variable must be recorded for the time
                  span from the starting date until today.)
                </small>
              </div>
              <div class="heartcore-container">
                <button
                  disabled={
                    props.app.driHeartCore.processing ||
                    !props.patient.heartCore.can_run_heartcore ||
                    !props.patient.consentOk()
                  }
                  onClick={() => {
                    if (props.app.driHeartCore.resultUrl) {
                      window.open(props.app.driHeartCore.resultUrl, "_blank");
                    } else {
                      props.app.driHeartCore.submit([props.patient.patientId]);
                    }
                  }}
                  class="heartcore-button"
                >
                  <Show when={props.app.driHeartCore.processing}>
                    <img
                      src="/static/imgs/sq_spin.png"
                      class="spinner"
                      style="width: 25px; height: 25px"
                    />
                  </Show>
                  <Show when={!props.app.driHeartCore.processing}>
                    {props.app.driHeartCore.buttonText}
                  </Show>
                </button>
                <button
                  disabled={
                    props.app.heartCoreReport.processing ||
                    !props.patient.heartCore.can_generate_monthly_report ||
                    !props.patient.consentOk()
                  }
                  onClick={() => {
                    if (props.app.heartCoreReport.resultUrl) {
                      window.open(
                        props.app.heartCoreReport.resultUrl,
                        "_blank",
                      );
                    } else {
                      props.app.heartCoreReport.submit([
                        props.patient.patientId,
                      ]);
                    }
                  }}
                  class="heartcore-button"
                >
                  <Show when={props.app.heartCoreReport.processing}>
                    <img
                      src="/static/imgs/sq_spin.png"
                      class="spinner"
                      style="width: 25px; height: 25px"
                    />
                  </Show>
                  <Show when={!props.app.heartCoreReport.processing}>
                    {props.app.heartCoreReport.buttonText}
                  </Show>
                </button>
              </div>
            </div>
          </Show>

          <div class="modal-container">
            <Tabs>
              <Show when={props.app.seerlinqApi.amILevel3}>
                <Tab
                  active={tabId() === "alerts"}
                  onClick={() => openTab("alerts")}
                >
                  Alerts
                </Tab>
              </Show>

              <Tab active={tabId() === "dri"} onClick={() => openTab("dri")}>
                DRI
              </Tab>

              <Tab
                active={tabId() === "vitals"}
                onClick={() => openTab("vitals")}
              >
                Vitals
              </Tab>

              <Tab
                active={tabId() === "symptoms"}
                onClick={() => openTab("symptoms")}
              >
                Symptoms
              </Tab>

              <Tab
                active={tabId() === "events"}
                onClick={() => openTab("events")}
              >
                Events
              </Tab>

              <Tab
                active={tabId() === "thresholds"}
                onClick={() => openTab("thresholds")}
              >
                Thresholds
              </Tab>

              <Tab
                active={tabId() === "medications"}
                onClick={() => openTab("medications")}
              >
                Medications
              </Tab>

              <Tab
                active={tabId() === "diags"}
                onClick={() => openTab("diags")}
              >
                Comorbidities
              </Tab>

              <Tab active={tabId() === "labs"} onClick={() => openTab("labs")}>
                Labs
              </Tab>

              <Tab
                active={tabId() === "exams"}
                onClick={() => openTab("exams")}
              >
                Exams
              </Tab>

              <Tab
                active={tabId() === "basics"}
                onClick={() => openTab("basics")}
              >
                Basic medical data
              </Tab>

              <Tab active={tabId() === "ppg"} onClick={() => openTab("ppg")}>
                PPG data
              </Tab>

              <Tab active={tabId() === "any"} onClick={() => openTab("any")}>
                Add any data
              </Tab>

              <Tab
                active={tabId() === "healthpro"}
                onClick={() => openTab("healthpro")}
              >
                HealthPro reporting
              </Tab>
            </Tabs>

            <Show when={!props.app.patientTabToggling}>
              <div class={clsx("modal-content", tabId() && "active")}>
                {props.children}
              </div>
            </Show>
          </div>
        </div>
      </Show>
    </div>
  );
}
