import clsx from "clsx";
import { createSignal, Show } from "solid-js";
import { PPGQuickLook } from "../../../quickLookAnalyses";

export function PPGShareDialog(props: { ppgQuickLook: PPGQuickLook }) {
  const [showCopiedNotice, setShowCopiedNotice] = createSignal(false);

  return (
    <div
      class={clsx(
        "quicklook-share",
        props.ppgQuickLook.sharePopupOpen && "active",
      )}
    >
      <p>
        Share <em>QuickLook</em> analysis.
      </p>
      <p>
        This link is valid for <strong>1 hour</strong>.
      </p>
      <p>
        <a
          style="color: #bb16a3"
          href={props.ppgQuickLook.sharedAnalysisUrl}
          class="long-link"
          target="_blank"
        >
          {props.ppgQuickLook.sharedAnalysisUrl}
        </a>
      </p>
      <button
        onClick={() => {
          navigator.clipboard
            .writeText(props.ppgQuickLook.sharedAnalysisUrl)
            .then(() => {
              setShowCopiedNotice(true);
              setTimeout(() => {
                setShowCopiedNotice(false);
              }, 2000);
            });
        }}
      >
        Copy Link
      </button>
      <button
        onClick={() => {
          props.ppgQuickLook.sharePopupOpen = false;
        }}
      >
        Close
      </button>
      <Show when={showCopiedNotice()}>
        <div class={clsx("copied-notice", showCopiedNotice() && "active")}>
          Link copied to clipboard!
        </div>
      </Show>
    </div>
  );
}
