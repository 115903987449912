import { alertTypes } from "../enumToText";
import { DataModel } from "./DataModel";

export class Alerts extends DataModel {
  // constants
  alertTypes = alertTypes;

  constructor(data) {
    super(data);
    this.sortBy = ["patient_id", "alert_timestamp"];
    this.sortByNames = ["Patient", "Timestamp"];
    this.dateTimeFields = ["alert_timestamp", "created_at"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = ["alert_variable", "alert_type", "alert_seen"];
    this.filteringListAttrs = ["alert_tags"];
  }
}
