export function CellCheckbox(props: {
  item: Record<string, any>;
  attr: string;
  label?: string;
}) {
  return (
    <label>
      {props.label}
      <input
        type="checkbox"
        checked={props.item[props.attr]}
        onChange={(event) => {
          props.item[props.attr] = event.target.checked;
        }}
      />
    </label>
  );
}
