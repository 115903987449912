import {
  AlertType,
  InformedConsent,
  PatientState,
  PatientStatus,
  PPGMeasurementMode,
  PPGQualityFlag,
  PPGRelatedQualityFlag,
  Realm,
  SeerlinqStudy,
} from "./api";

export const patientStates: Record<PatientState, string> = {
  [PatientState.normal]: "Normal",
  [PatientState.high_risk]: "High risk",
};

export const patientStatuses: Record<PatientStatus, string> = {
  [PatientStatus.test]: "Test",
  [PatientStatus.active]: "Active",
  [PatientStatus.not_active]: "Not active",
  [PatientStatus.mark_for_deletion]: "Marked for deletion",
  [PatientStatus.dead]: "Dead",
};

export const seerlinqStudies: Record<SeerlinqStudy, string> = {
  [SeerlinqStudy.no_study]: "No study",
  [SeerlinqStudy.commercial]: "Commercial",
  [SeerlinqStudy.sq_validation_study]: "Seerlinq-validation study",
  [SeerlinqStudy.test_monitoring]: "Test home-monitoring",
  [SeerlinqStudy.stop_dhf]: "#STOP-DHF",
  [SeerlinqStudy.clinic_single]: "Clinic: single measurement",
};

export const seerlinqRealms: Record<Realm, string> = {
  [Realm.dummy]: "Dummy",
  [Realm.sk_bc]: "SK: Banskobystrický kraj",
  [Realm.sk_bl]: "SK: Bratislavský kraj",
  [Realm.sk_ki]: "SK: Košický kraj",
  [Realm.sk_ni]: "SK: Nitriansky kraj",
  [Realm.sk_pv]: "SK: Prešovský kraj",
  [Realm.sk_ta]: "SK: Trnavský kraj",
  [Realm.sk_tc]: "SK: Trenčiansky kraj",
  [Realm.sk_zi]: "SK: Žilinský kraj",
  [Realm.other]: "Other",
};

export const informedConsent: Record<InformedConsent, string> = {
  [InformedConsent.none]: "None",
  [InformedConsent.telemonitoring]: "Telemonitoring",
  [InformedConsent.paper]: "Paper",
};

export const ppgRelatedFlag: Record<PPGRelatedQualityFlag, string> = {
  [PPGRelatedQualityFlag.wrong]: "Wrong",
  [PPGRelatedQualityFlag.ok]: "OK",
  [PPGRelatedQualityFlag.low_ppg_quality]: "Low PPG Quality",
  [PPGRelatedQualityFlag.ignore_data]: "Ignore",
};

export const ppgConditions = [
  "Condition 1",
  "Condition 2",
  "Condition 3",
  "Condition 4",
  "Condition 5",
];

export const ppgMeasModes: Record<PPGMeasurementMode, string> = {
  [PPGMeasurementMode.external_data]: "external",
  [PPGMeasurementMode.seerlinq_supervision]: "Seerlinq supervison",
  [PPGMeasurementMode.physician_supervison]: "physician supervision",
  [PPGMeasurementMode.home_monitoring]: "home-monitoring",
};

export const ppgQuality: Record<PPGQualityFlag, string> = {
  [PPGQualityFlag.unknown]: "Unknown",
  [PPGQualityFlag.data_error]: "Data error",
  [PPGQualityFlag.ok]: "OK",
  [PPGQualityFlag.low_signal_quality]: "Low signal quality",
  [PPGQualityFlag.artefacts]: "Artefacts",
  [PPGQualityFlag.short_signal]: "Short",
  [PPGQualityFlag.extra_circumstance]: "Extra",
  [PPGQualityFlag.test_measurement]: "Testing",
};

export const alertTypes: Record<AlertType, string> = {
  [AlertType.low_critical]: "CRITICAL low",
  [AlertType.low_alert]: "Alert low",
  [AlertType.low_warning]: "warning low",
  [AlertType.normal]: "normal",
  [AlertType.high_warning]: "warning high",
  [AlertType.high_alert]: "Alert high",
  [AlertType.high_critical]: "CRITICAL high",
};
