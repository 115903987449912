export const diagConfidence: number[] = [0, 25, 50, 75, 100];
export const medicalOptions: Record<string, string[] | number[]> = {
  // exams basic
  "ECG rhythm": ["SR", "AF", "Paced", "Other"],
  "ECG premature contractions": ["No", "Yes"],
  "leg edema": [0, 1, 2, 3, 4],
  "jugular venous distention": ["No", "Yes"],
  // echo
  "elevated LV filling pressure": ["No", "Yes"],
  AoS: ["None", "Mild", "Moderate", "Severe"],
  AoR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  MR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  MS: ["None", "Mild", "Moderate", "Severe"],
  TR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  PuR: ["None", "Trace", "Mild", "Moderate", "Severe"],
  "B-lines on lung-ultrasound": ["No", "Yes"],
  "pleural effusion": ["None", "Minor", "Major"],
  ascites: ["None", "Minor", "Major"],
  "elevated LV filling pressure final outcome": ["No", "Yes"],
};
export const eventTypes = {
  patient_contact: "Patient contact",
  medical_call: "Medical call",
  medication_change: "Medication change",
  check_up: "Check-up",
  labs: "Labs",
  hospitalization: "Hospitalization",
  death: "Death",
  patient_non_adherence: "Patient non-adherence",
  physician_notified: "Treating physician notified",
};
export const tokenExpiries = {
  P1D: "1 day",
  P2D: "2 days",
  P1W: "1 week",
  P2W: "2 weeks",
};

export const languages = ["sk", "en"];

export const connectedRoles = ["patient-ppg-app", "patient"];

export const managingRoles = [
  "physician-ppg-app",
  "physician",
  "study-physician",
];

export const sexes = ["M", "F"];

export const races = [
  "Caucasian",
  "Asian",
  "American Indian / Alaska Native",
  "Black or African American",
  "Hawaiian or Other Pacific Islander",
  "More Than One Race",
  "Arabic",
  "Unknown / Not Reported",
];

export const deviceHandovers = {
  patient_in_person: "in-person",
  patient_remote_send: "sending by post",
};

export const routePatients = "/";
export const routePatient = "/patient/:id";
export const routePatientTab = "/patient/:id/:tab";
export const routeAddPatient = "/add-patient";
export const routeWaitingRoom = "/waiting-room";
export const routeAdmin = "/admin";
export const routeAdminTab = "/admin/:adtab";
