import { createSignal, Show } from "solid-js";
import { AdminConsole } from "../../adminUtils";
import { ListMonitoringTeamsResponse } from "../../api";
import { seerlinqRealms } from "../../enumToText";
import { App } from "../../main";
import { SimpleTable } from "./SimpleTable";

export function TeamsAdmin(props: { admin: AdminConsole; app: App }) {
  const [teams, setTeams] = createSignal<ListMonitoringTeamsResponse>();

  const fetchTeams = async () => {
    const teams = await props.app.seerlinqApi.getMonitoringTeams();
    setTeams(teams);
  };

  fetchTeams();

  return (
    <Show when={teams()}>
      <div id="modal-content">
        <h2>Monitoring teams</h2>
        <span>
          We have <strong>{teams().results}</strong> monitoring teams in the
          database.
        </span>
        <br />
        <div class="table-container">
          <SimpleTable
            rows={teams().monitoring_teams.flatMap((team, teamIndex) =>
              team.members.map((member, memberIndex) => ({
                team,
                teamIndex,
                member,
                memberIndex,
              })),
            )}
            columns={[
              {
                header1: "Name",
                cell: ({ team }) => team.display_name,
              },
              {
                header1: "Realm",
                cell: ({ team }) => seerlinqRealms[team.realm],
              },
              {
                header1: "Admin",
                cell: ({ team }) => team.admin.username,
              },
              {
                header1: "Members",
                header2: "Username",
                cell: ({ member }) => member.username,
              },
              {
                header1: "",
                header2: "Role",
                cell: ({ member }) => member.role,
              },
              {
                header1: "",
                header2: "Is active",
                cell: ({ member }) => member.monitoring_active,
              },

              {
                header1: "",
                header2: "# of monitored patients",
                cell: ({ member }) => member.monitoring_patients.length,
              },
            ]}
          />
        </div>
      </div>
    </Show>
  );
}
