import { AddDiags } from "../../../dataAdd/AddDiags";
import { Patient } from "../../../patient";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellSelect } from "../../uiKit/addTable/CellSelect";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddDiagsTable(props: { model: AddDiags; patient: Patient }) {
  return (
    <AddTable
      model={props.model}
      setAllDates="date"
      columns={[
        {
          header: "Name",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="diagnosis_name"
              item={item}
              values={Object.keys(props.patient.diagOptions)}
              listTitle="Select diagnosis"
            />
          ),
        },
        {
          header: "Value",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="diagnosis_value"
              item={item}
              values={props.patient.diagOptions[item.diagnosis_name]}
            />
          ),
        },
        {
          header: "Diagnosed at",
          cell: (item) => (
            <CellDate attr="diagnosed_at" item={item} type="date" />
          ),
        },
        {
          header: "Remission",
          cell: (item) => <CellDate attr="remission" item={item} type="date" />,
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="comment" item={item} />,
        },
        {
          header: "Remove diagnosis",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
    />
  );
}
