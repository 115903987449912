import { AddEvents } from "../../../dataAdd/AddEvents";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellSelect } from "../../uiKit/addTable/CellSelect";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddEventsTable(props: { model: AddEvents }) {
  return (
    <AddTable
      model={props.model}
      setAllDates="date"
      columns={[
        {
          header: "Event date",
          mandatory: true,
          cell: (item) => (
            <CellDate attr="event_date" item={item} type="date" />
          ),
        },
        {
          header: "Event type",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="event_type"
              item={item}
              values={props.model.eventTypes}
              listTitle="Select type"
            />
          ),
        },
        {
          header: "Description",
          mandatory: true,
          cell: (item) => <CellText attr="event_description" item={item} />,
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="event_comment" item={item} />,
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
    />
  );
}
