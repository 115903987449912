import { effect, stop } from "@vue/reactivity";
import { enableExternalSource } from "solid-js";

export function initSolid() {
  // We can use Solid with @vue/reactivity thanks to this snippet
  enableExternalSource((fn, trigger) => {
    let effectFn = () => {};
    const ef = effect(() => effectFn(), { scheduler: () => trigger() });

    return {
      track: (x) => {
        let next: unknown;
        effectFn = () => (next = fn(x));
        ef();
        return next;
      },
      dispose: () => {
        stop(ef);
      },
    };
  });
}
