import { For, Show } from "solid-js";

export function CellSelect(props: {
  item: Record<string, any>;
  attr: string;
  /** Either an enum or just an array of possible values. */
  values: Record<string, string> | string[];
  listTitle?: string;
  onChange?: (newValue: string) => void;
  disabled?: boolean;
}) {
  return (
    <Show when={!props.disabled} fallback={props.item[props.attr]}>
      <select
        value={props.item[props.attr]}
        onChange={(event) => {
          const newValue = event.target.value;
          props.item[props.attr] = newValue;
          props.onChange?.(newValue);
        }}
      >
        {props.listTitle && (
          <option value="null" disabled>
            {props.listTitle}
          </option>
        )}

        <For
          each={
            Array.isArray(props.values)
              ? props.values
              : Object.keys(props.values)
          }
        >
          {(val) => (
            <option value={val}>
              {Array.isArray(props.values) ? val : props.values[val]}
            </option>
          )}
        </For>
      </select>
    </Show>
  );
}
