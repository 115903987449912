import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../../../apiConnector";
import { medicalOptions } from "../../../constants";
import { AddEcho } from "../../../dataAdd/AddEcho";
import { AddOtherExams } from "../../../dataAdd/AddOtherExams";
import { AddRHC } from "../../../dataAdd/AddRHC";
import { App } from "../../../main";
import { Patient } from "../../../patient";
import { floatRounding, getObjectFromArray } from "../../../utils";
import { CellDateEditable } from "../../uiKit/table/CellDateEditable";
import { CellEditButtons } from "../../uiKit/table/CellEditButtons";
import { CellSelectEditable } from "../../uiKit/table/CellSelectEditable";
import { CellTextEditable } from "../../uiKit/table/CellTextEditable";
import { HeaderSort } from "../../uiKit/table/HeaderSort";
import { Table } from "../../uiKit/table/Table";
import { AddExamsTable } from "../add/AddExamsTable";
import { AddRHCTable } from "../add/AddRHCTable";

export function ExamsTable(props: {
  patient: Patient;
  api: ApiConnector;
  app: App;
}) {
  const model = createMemo(() => props.patient.exams);

  return (
    <div>
      <h3>Exams:</h3>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "exams");
        }}
      >
        Add other (ECG / final outcome) exams data
      </button>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "echo");
        }}
      >
        Add ECHO exams data
      </button>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "rhc");
        }}
      >
        Add RHC exams data
      </button>

      <Show when={props.app.addingActive && !props.app.togglingDataAdd}>
        <Show when={props.app.dataAdd instanceof AddOtherExams}>
          <AddExamsTable model={props.app.dataAdd as AddOtherExams} />
        </Show>

        <Show when={props.app.dataAdd instanceof AddEcho}>
          <AddExamsTable model={props.app.dataAdd as AddEcho} />
        </Show>

        <Show when={props.app.dataAdd instanceof AddRHC}>
          <AddRHCTable model={props.app.dataAdd as AddRHC} />
        </Show>
      </Show>

      <br />
      <br />
      <br />

      <Table
        model={model()}
        columns={[
          {
            header1: (
              <HeaderSort
                model={model()}
                attr="measurement_datetime"
                name="Measured"
              />
            ),
            cell: (item, index) => (
              <CellDateEditable
                attr="measurement_datetime"
                index={index}
                model={model()}
                type="datetime-local"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="measurement_type" name="Type" />
            ),
            cell: (item) => item.measurement_type,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <>
                <Show when={!(item.measurement_type in medicalOptions)}>
                  <CellTextEditable
                    attr="measurement_value"
                    index={index}
                    model={model()}
                    displayValue={floatRounding(item.measurement_value, 1)}
                    width={50}
                  />
                </Show>
                <Show when={item.measurement_type in medicalOptions}>
                  <CellSelectEditable
                    attr="measurement_value"
                    index={index}
                    model={model()}
                    values={getObjectFromArray(
                      medicalOptions[item.measurement_type],
                    )}
                  />
                </Show>
              </>
            ),
          },
          { header1: "Unit", cell: (item) => item.measurement_unit },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <>
                <Show
                  when={!model().notEditable.includes(item.measurement_type)}
                >
                  <CellEditButtons
                    model={model()}
                    index={index}
                    onConfirm={(editedField) => {
                      props.patient.updateItem(
                        "medicaldata",
                        item.uuid,
                        editedField,
                        model().dateTimeFields,
                      );
                    }}
                  />
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          props.api.amIAdmin && {
            header1: "Delete",
            cell: (item) => (
              <>
                <Show
                  when={!model().notEditable.includes(item.measurement_type)}
                >
                  <button
                    onClick={() => {
                      props.patient.deleteItem("medicaldata", item.uuid);
                    }}
                  >
                    Delete
                  </button>
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          props.patient.vitals.showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          props.patient.vitals.showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </div>
  );
}
