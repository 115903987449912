import { createMemo, Show } from "solid-js";
import {
  patientStatuses,
  seerlinqRealms,
  seerlinqStudies,
} from "../../enumToText";
import { ModalWindow } from "../../modals";
import { CellSelectRow } from "../uiKit/table/CellSelectRow";
import { HeaderSelectFilter } from "../uiKit/table/HeaderSelectFilter";
import { HeaderSelectRows } from "../uiKit/table/HeaderSelectRows";
import { HeaderSort } from "../uiKit/table/HeaderSort";
import { Table } from "../uiKit/table/Table";

export function PatientsModal(props: { modal: ModalWindow }) {
  const modal = createMemo(() => props.modal);

  return (
    <Show when={modal()?.modalOpen}>
      <div class="floating-modal active">
        <div class="floating-modal-content">
          <button
            class="floating-modal-close-button"
            onClick={() => modal().closeModal()}
          >
            X
          </button>
          <h3>{modal().modalText}</h3>

          <Table
            columns={[
              {
                header1: "Choose",
                header2: (
                  <HeaderSelectRows model={modal()} idAttr="patient_id" />
                ),
                cell: (item) => (
                  <CellSelectRow id={item.patient_id} model={modal()} />
                ),
              },
              {
                header1: "Patient ID",
                header2: (
                  <HeaderSort attr="patient_id" model={modal().modalData} />
                ),
                cell: (item) => item.patient_id,
              },
              {
                header1: "Added",
                header2: (
                  <HeaderSort attr="created_at" model={modal().modalData} />
                ),
                cell: (item) => item.created_at,
              },
              {
                header1: "Added by",
                header2: (
                  <HeaderSelectFilter
                    attr="added_by"
                    model={modal().modalData}
                    name="who added"
                  />
                ),
                cell: (item) => item.user.username,
              },
              {
                header1: "Status",
                header2: (
                  <HeaderSelectFilter
                    attr="patient_status"
                    model={modal().modalData}
                    name="Status"
                    values={patientStatuses}
                  />
                ),
                cell: (item) => patientStatuses[item.patient_status],
              },
              {
                header1: "Study",
                header2: (
                  <HeaderSelectFilter
                    attr="patient_study"
                    model={modal().modalData}
                    name="Studies"
                    values={seerlinqStudies}
                  />
                ),
                cell: (item) =>
                  item.patient_study
                    .map((st) => seerlinqStudies[st])
                    .join("; "),
              },
              {
                header1: "Realm",
                header2: (
                  <HeaderSelectFilter
                    attr="realm"
                    model={modal().modalData}
                    name="Realm"
                    values={seerlinqRealms}
                  />
                ),
                cell: (item) => seerlinqRealms[item.realm],
              },
              {
                header1: "Description",
                cell: (item) => item.description,
              },
            ]}
            model={modal().modalData}
            isRowSelected={(item) => modal().isSelected(item.patient_id)}
          />

          <br />

          <button
            disabled={modal().chooseSingle && modal().finalSingleChoice == null}
            onClick={() => {
              modal().closeModal();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </Show>
  );
}
