import { createMemo, Show } from "solid-js";
import { AdminConsole } from "../../adminUtils";
import { AddClinic } from "../../dataAdd/AddClinic";
import { seerlinqRealms } from "../../enumToText";
import { App } from "../../main";
import { PhysiciansModal } from "../modals/PhysiciansModal";
import { ChooseField } from "../uiKit/form/ChooseField";
import { FormRow } from "../uiKit/form/FormRow";
import { SelectField } from "../uiKit/form/SelectField";
import { TextField } from "../uiKit/form/TextField";
import { CellChoose } from "../uiKit/table/CellChoose";
import { CellEditButtons } from "../uiKit/table/CellEditButtons";
import { CellSelectEditable } from "../uiKit/table/CellSelectEditable";
import { CellTextEditable } from "../uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "../uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "../uiKit/table/HeaderSort";
import { Table } from "../uiKit/table/Table";

export function ClinicsAdmin(props: { admin: AdminConsole; app: App }) {
  const model = createMemo(() => props.admin.clinics);
  const dataAdd = createMemo(() => props.app.dataAdd as AddClinic);

  return (
    <div id="modal-content">
      <PhysiciansModal modal={props.admin.physiciansModal} />

      <h2>Clinics</h2>
      <span>
        We have <strong>{props.admin.clinics.data.length}</strong> clinic(s) in
        the database.
      </span>

      <br />
      <br />

      <button
        onClick={() => {
          props.admin.toggleCreateClinic();
        }}
      >
        {props.admin.addingClinic ? "Cancel" : "Create new clinic"}
      </button>

      <br />
      <br />

      <Show when={!props.admin.addingClinic}>
        <Table
          columns={[
            {
              header1: "Name",
              header2: <HeaderSort attr="name" model={model()} />,
              cell: (item, index) => (
                <CellTextEditable
                  attr="name"
                  index={index}
                  model={model()}
                  width={90}
                  displayValue={<strong>{item.name}</strong>}
                />
              ),
            },
            {
              header1: "Ambulance",
              cell: (item, index) => (
                <CellTextEditable
                  attr="ambulance"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Address",
              cell: (item, index) => (
                <CellTextEditable
                  attr="address"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Country",
              header2: (
                <HeaderSelectFilter
                  attr="country"
                  model={model()}
                  name="Country"
                />
              ),
              cell: (item, index) => (
                <CellTextEditable
                  attr="country"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Realm",
              header2: (
                <HeaderSelectFilter
                  attr="realm"
                  model={model()}
                  name="Realm"
                  values={seerlinqRealms}
                />
              ),
              cell: (item, index) => (
                <CellSelectEditable
                  attr="realm"
                  values={seerlinqRealms}
                  index={index}
                  model={model()}
                />
              ),
            },
            {
              header1: "Phone",
              cell: (item, index) => (
                <CellTextEditable
                  attr="phone"
                  index={index}
                  model={model()}
                  width={60}
                />
              ),
            },
            {
              header1: "Email",
              cell: (item, index) => (
                <CellTextEditable
                  attr="email"
                  index={index}
                  model={model()}
                  width={80}
                />
              ),
            },
            {
              header1: "# of physicians",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  onClick={() => {
                    props.admin.editPhysiciansForClinic(index);
                  }}
                  valueEdit={props.admin.currentPhysiciansForClinic(index)}
                  valueView={item.numPhysicians}
                />
              ),
            },
            {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updateClinic(item.uuid, editedField);
                  }}
                  onCleanup={() => {
                    props.admin.resetModals();
                  }}
                />
              ),
            },
          ]}
          model={model()}
        />
      </Show>

      <Show
        when={
          props.admin.addingClinic &&
          props.app.dataInited &&
          dataAdd() instanceof AddClinic
        }
      >
        <div>
          <h3>Add new clinic</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Name"
                data={<TextField attr="name" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Ambulance"
                data={
                  <TextField attr="ambulance" model={dataAdd().addingList} />
                }
                mandatory
              />

              <FormRow
                label="Address"
                data={<TextField attr="address" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Country"
                data={<TextField attr="country" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Monitoring realm"
                data={
                  <SelectField
                    attr="realm"
                    model={dataAdd().addingList}
                    values={seerlinqRealms}
                    intValue
                  />
                }
                mandatory
              />

              <FormRow
                label="(General) Phone"
                data={<TextField attr="phone" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="(General) Email"
                data={<TextField attr="email" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Clinic physicians"
                data={
                  <ChooseField
                    value={`${props.admin.physiciansModal.finalMultiChoice.length} chosen`}
                    onClick={() => {
                      props.admin.choosePhysiciansForClinic(
                        dataAdd().addingList.realm,
                      );
                    }}
                  />
                }
              />
            </table>

            <br />
            <br />

            <button
              type="button"
              onClick={() => {
                dataAdd().post(props.admin.physiciansModal.finalMultiChoice);
              }}
            >
              Add clinic
            </button>
          </form>
        </div>
      </Show>
    </div>
  );
}
