import { createMemo } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

export function CellCheckboxEditable(props: {
  index: number;
  model: DataModel;
  attr: string;
}) {
  const item = createMemo(() => props.model.paginatedData[props.index]);
  const editing = createMemo(
    () =>
      props.model.editingId === props.index &&
      props.model.canEdit(props.attr, item()),
  );

  return (
    <input
      type="checkbox"
      checked={
        editing() ? props.model.editedField?.[props.attr] : item()[props.attr]
      }
      onChange={(event) => {
        props.model.editedField[props.attr] = event.target.checked;
      }}
      disabled={!editing()}
    />
  );
}
