import { Show } from "solid-js";
import { AddRHC } from "../../../dataAdd/AddRHC";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddRHCTable(props: { model: AddRHC }) {
  return (
    <AddTable
      model={props.model}
      canAddRows={false}
      setAllDates="datetime"
      columns={[
        {
          header: "Measured at",
          cell: (item) => (
            <CellDate
              attr="measurement_datetime"
              item={item}
              type="datetime-local"
            />
          ),
        },
        {
          header: "Type",
          cell: (item) => (
            <label>
              {item.measurement_type.charAt(0).toUpperCase() +
                item.measurement_type.slice(1)}
            </label>
          ),
        },
        {
          header: "Required",
          cell: (item) => (
            <>
              <Show
                when={props.model.requiredEitherOr.includes(
                  item.measurement_type,
                )}
              >
                <span style="color: #bb16a3">*</span>
              </Show>
              <Show
                when={
                  !props.model.requiredEitherOr.includes(item.measurement_type)
                }
              >
                <span
                  style={
                    props.model.required.includes(item.measurement_type)
                      ? "color: #bb16a3"
                      : "font-size: 0.8em"
                  }
                >
                  {props.model.required.includes(item.measurement_type)
                    ? "Yes"
                    : "No"}
                </span>
              </Show>
            </>
          ),
        },
        {
          header: "Value",
          cell: (item) => (
            <CellText attr="measurement_value" item={item} width={70} />
          ),
        },
        {
          header: "Unit",
          cell: (item) => <label>{item.measurement_unit}</label>,
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="comment" item={item} />,
        },
      ]}
      footer={
        <span>
          <span style="color: #bb16a3">*</span> = either Fick or TD required,
          you can enter both measurements
        </span>
      }
    />
  );
}
