import { Show } from "solid-js";
import { AddSymptoms } from "../../../dataAdd/AddSymptoms";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellCheckbox } from "../../uiKit/addTable/CellCheckbox";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddSymptomsTable(props: { model: AddSymptoms }) {
  return (
    <AddTable
      model={props.model}
      canAddRows={false}
      setAllDates="date"
      infoExtra={
        <>
          <h4 style="color: #bb16a3">Required symptoms endpoints</h4>
          (Please, use UTC dates to avoid misconceptions.)
        </>
      }
      columns={[
        {
          header: "Symptom date",
          cell: (item) => (
            <CellDate attr="symptom_date" type="date" item={item} />
          ),
        },
        {
          header: "Symptom",
          cell: (item) => (
            <label>
              {item.symptom_name.charAt(0).toUpperCase() +
                item.symptom_name.slice(1)}
            </label>
          ),
        },
        {
          header: "Value",
          cell: (item) => (
            <CellText attr="symptom_value" item={item} type="number" />
          ),
        },
        {
          header: "Change in 6 months?",
          cell: (item) => (
            <CellCheckbox
              attr="symptom_change_in_last_six_m"
              item={item}
              label="Yes:"
            />
          ),
        },
        {
          header: "Change date",
          cell: (item) => (
            <Show when={item.symptom_change_in_last_six_m}>
              <CellDate attr="symptom_change_date" item={item} type="date" />
            </Show>
          ),
        },
        {
          header: "Value before",
          cell: (item) => (
            <Show when={item.symptom_change_in_last_six_m}>
              <CellText attr="symptom_value_before" item={item} type="number" />
            </Show>
          ),
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="comment" item={item} />,
        },
      ]}
    />
  );
}
