import { DataAdd } from "../../../dataAdd/DataAdd";

export function CellRemoveButton(props: { model: DataAdd; index: number }) {
  return (
    <div style={{ display: "flex", "justify-content": "center" }}>
      <button
        type="button"
        style={{ "font-size": "1.2em" }}
        onClick={() => {
          props.model.removeItem(props.index);
        }}
      >
        &times;
      </button>
    </div>
  );
}
