import { For } from "solid-js";

export function RadioField(props: {
  attr: string;
  model: Record<string, any>;
  values: string[] | number[] | Record<string, string>;
}) {
  return (
    <For
      each={
        Array.isArray(props.values) ? props.values : Object.keys(props.values)
      }
    >
      {(value) => (
        <label>
          <span>
            {Array.isArray(props.values) ? value : props.values[value]}:
          </span>{" "}
          <input
            type="radio"
            value={value}
            checked={props.model[props.attr] === value}
            onChange={(event) => {
              if (event.target.checked) {
                props.model[props.attr] = value;
              }
            }}
          />{" "}
        </label>
      )}
    </For>
  );
}
