import { reactive } from "@vue/reactivity";
import { render } from "solid-js/web";
import { AdminConsole } from "./adminUtils";
import { apiGetAlerts, apiGetClinics, apiGetPhysicians } from "./api";
import { ApiConnector, setupApiClient } from "./apiConnector";
import { AppRoot } from "./components/AppRoot";
import { apiRoute, clvVersion } from "./config";
import {
  connectedRoles,
  languages,
  managingRoles,
  medicalOptions,
  tokenExpiries,
} from "./constants";
import { AddAnyData } from "./dataAdd/AddAnyData";
import { AddBasics } from "./dataAdd/AddBasics";
import { AddClinic } from "./dataAdd/AddClinic";
import { AddDiags } from "./dataAdd/AddDiags";
import { AddEcho } from "./dataAdd/AddEcho";
import { AddEvents } from "./dataAdd/AddEvents";
import { AddLabs } from "./dataAdd/AddLabs";
import { AddMedication } from "./dataAdd/AddMedication";
import { AddOtherExams } from "./dataAdd/AddOtherExams";
import { AddPatient } from "./dataAdd/AddPatient";
import { AddPhysician } from "./dataAdd/AddPhysician";
import { AddPPG } from "./dataAdd/AddPPG";
import { AddRHC } from "./dataAdd/AddRHC";
import { AddSymptoms } from "./dataAdd/AddSymptoms";
import { AddThresholds } from "./dataAdd/AddThresholds";
import { AddUser } from "./dataAdd/AddUser";
import { AddVitals } from "./dataAdd/AddVitals";
import { DataAdd } from "./dataAdd/DataAdd";
import { Alerts } from "./dataModels/Alerts";
import { Clinics } from "./dataModels/Clinics";
import { Patients } from "./dataModels/Patients";
import { Physicians } from "./dataModels/Physicians";
import { Users } from "./dataModels/Users";
import {
  informedConsent,
  patientStates,
  patientStatuses,
  ppgConditions,
  ppgMeasModes,
  ppgQuality,
  seerlinqRealms,
  seerlinqStudies,
} from "./enumToText";
import { initSolid } from "./initSolid";
import { Patient } from "./patient";
import { SimplePlot } from "./plotting";
import {
  PPGQuickLook,
  SeerlinqDRIHeartCore,
  SeerlinqHCReport,
} from "./quickLookAnalyses";
import { initSentry } from "./sentry";

export class App {
  clvVersion = clvVersion ?? "-";
  darkMode = localStorage.getItem("darkMode") === "true";

  // basics
  loading = true;
  loadingPatients = true;
  loadingAdmin = true;
  defaultApiPageSize = 25;
  patients: Patients;

  // patient loading and tabs
  loadingPatient = true;
  patient = null;
  ppgQuickLook = null;
  driHeartCore = null;
  heartCoreReport = null;
  activePatientModal: PatientTabId = null;
  patientTabToggling = true;
  patientModalContent = "";

  // admin
  adminShit: AdminConsole = null;
  activeAdminModal: AdminTabId = null;
  adminTabToggling = true;
  adminModalContent = "";

  // data adding
  dataAdd = {};
  dataInited = false;

  togglingDataAdd = false;
  addingActive = false;
  addingId = null;
  dataAddContent = "";

  // editing helpers
  editingId = null;
  editedField = {};

  // app-wide constants
  patientStates = patientStates;
  patientStatuses = patientStatuses;
  seerlinqStudies = seerlinqStudies;
  seerlinqRealms = seerlinqRealms;
  informedConsent = informedConsent;
  tokenExpiries = tokenExpiries;
  ppgConditions = ppgConditions;
  ppgMeasModes = ppgMeasModes;
  ppgQuality = ppgQuality;
  medicalOptions = medicalOptions;
  languages = languages;
  connectedRoles = connectedRoles;
  managingRoles = managingRoles;

  constructor(public seerlinqApi: ApiConnector) {}

  // basic loaders

  async initIndex() {
    this.setTheme();
    await this.seerlinqApi.checkLoggedIn();
    this.loading = false;
  }

  async getPatients() {
    this.loadingPatients = true;
    await this.initIndex();
    this.patients = new Patients(this.seerlinqApi, this.defaultApiPageSize);

    // init with HeartCore
    await this.patients.init(true, false);
    this.loadingPatients = false;
    // finish fetching
    await this.patients.fetchNextPage(true, true);
  }

  initPPGQuickLook() {
    const ppgQuickLook = new PPGQuickLook(this.seerlinqApi);
    this.ppgQuickLook = ppgQuickLook;
  }

  initHeartCore() {
    const driHeartCore = new SeerlinqDRIHeartCore(this.seerlinqApi);
    this.driHeartCore = driHeartCore;
    const heartCoreReport = new SeerlinqHCReport(this.seerlinqApi);
    this.heartCoreReport = heartCoreReport;
  }

  async loadPatient(patientId: number, activeTab: PatientTabId = "alerts") {
    const patient = new Patient(this.seerlinqApi);
    await patient.fetch(patientId);
    this.patient = patient;
    if (this.seerlinqApi.amILevel3) {
      this.initPPGQuickLook();
      this.initHeartCore();
    }
    this.loadingPatient = false;
    // DRI modal is active by default
    await this.switchPatientTab(activeTab);
  }

  async initAlertSystem() {
    await this.seerlinqApi.checkLoggedIn();
    const alerts = await apiGetAlerts({ throwOnError: true });
    const alertSystem = new Alerts(alerts.data.alerts);
    alertSystem.init();

    return alertSystem;
  }

  async initAdmin(activeTab: AdminTabId = "clinics") {
    await this.seerlinqApi.checkLoggedIn();
    if (this.seerlinqApi.amILevel3) {
      this.loadingAdmin = true;
      await this.seerlinqApi.checkLoggedIn();
      const clinicsResponse = await apiGetClinics({ throwOnError: true });
      const clinics = new Clinics(clinicsResponse.data.clinics);
      clinics.init();

      const physiciansResponse = await apiGetPhysicians({ throwOnError: true });
      const physicians = new Physicians(physiciansResponse.data.physicians);
      physicians.init();

      const patients = new Patients(this.seerlinqApi, this.defaultApiPageSize);
      await patients.init(false, false);

      const users = new Users(this.seerlinqApi);
      if (this.seerlinqApi.amIAdmin) {
        await users.init();
      } else {
        await users.initPhysiciansOnly();
      }

      this.adminShit = new AdminConsole(
        this.seerlinqApi,
        clinics,
        physicians,
        patients,
        users,
        this,
      );
      await this.adminShit.init();
      this.loadingAdmin = false;
      await this.switchAdminTab(activeTab);
      await patients.fetchNextPage(false, true);
    }
  }

  // navigation
  resetAdding() {
    this.addingActive = false;
    this.dataAdd = {};
    this.dataInited = false;
    this.dataAddContent = "";
  }

  async adminNavigate(tab: AdminTabId, reload: boolean = false) {
    window.navigate(`/admin/${tab}`);
    if (reload) {
      window.location.reload();
    } else {
      await this.switchAdminTab(tab);
    }
  }

  async switchAdminTab(tab: AdminTabId) {
    this.adminShit.addingUser = false;
    this.adminShit.addingClinic = false;
    this.adminShit.addingPhysician = false;
    this.resetAdding();

    if (tab === "clinics") {
      await this.toggleAdminModal("clinics");
    } else if (tab === "physicians") {
      await this.toggleAdminModal("physicians");
    } else if (tab === "teams") {
      await this.toggleAdminModal("teams");
    } else if (tab === "limiters") {
      await this.toggleAdminModal("limiters");
    } else if (tab === "users") {
      await this.toggleAdminModal("users");
    }
  }

  async toggleAdminModal(modalId: AdminTabId) {
    this.activeAdminModal = modalId;
    this.adminTabToggling = false;
  }

  async patientNavigate(
    patId: number | string,
    tab: PatientTabId,
    reload: boolean = false,
  ) {
    window.navigate(`/patient/${patId}/${tab}`);
    if (reload) {
      window.location.reload();
    } else {
      await this.switchPatientTab(tab);
    }
  }

  async switchPatientTab(tab: PatientTabId) {
    this.resetAdding();
    if (tab === "alerts") {
      await this.togglePatientDataModal("alerts");
    } else if (tab === "dri") {
      await this.togglePatientDataModal("dri", this.patient.driDataPlot);
    } else if (tab === "vitals") {
      await this.togglePatientDataModal("vitals", this.patient.vitalsPlot);
    } else if (tab === "symptoms") {
      await this.togglePatientDataModal("symptoms", this.patient.symptomPlot);
    } else if (tab === "events") {
      await this.togglePatientDataModal("events");
    } else if (tab === "thresholds") {
      await this.togglePatientDataModal("thresholds");
    } else if (tab === "medications") {
      await this.togglePatientDataModal("medications");
    } else if (tab === "diags") {
      await this.togglePatientDataModal("diags");
    } else if (tab === "labs") {
      await this.togglePatientDataModal("labs", this.patient.labsPlot);
    } else if (tab === "exams") {
      await this.togglePatientDataModal("exams");
    } else if (tab === "basics") {
      await this.togglePatientDataModal("basics", this.patient.basicMedPlot);
    } else if (tab === "ppg") {
      await this.togglePatientDataModal("ppg");
    } else if (tab === "any") {
      await this.initAdding("any");
      await this.togglePatientDataModal("any");
    } else if (tab === "healthpro") {
      await this.togglePatientDataModal("healthpro");
    } else {
      await this.togglePatientDataModal("alerts");
    }
  }

  async togglePatientDataModal(
    modalId: PatientTabId,
    plotHandler: SimplePlot = null,
  ) {
    this.patientTabToggling = true;
    this.activePatientModal =
      this.activePatientModal === modalId ? null : modalId;

    if (plotHandler != null) {
      plotHandler.resetPlot();
      plotHandler.plotType = null;
      plotHandler.plotStart = null;
      plotHandler.plotEnd = null;
    }
    this.patientTabToggling = false;
  }

  // editing helpers
  startEditing(editId: number, init_value: any) {
    this.editingId = editId;
    this.editedField = init_value;
  }

  stopEditing(resetTo: any = {}) {
    this.editingId = null;
    this.editedField = resetTo;
  }

  // adding data
  async toggleDataAddSection(filePath: string, modalId: string) {
    this.togglingDataAdd = true;
    if (this.addingId === modalId) {
      this.addingActive = !this.addingActive;
    } else {
      this.addingActive = true;
      this.addingId = modalId;
    }

    if (this.addingActive) {
      await this.initAdding(modalId);
      this.dataAddContent = "";
      if (filePath) {
        await fetch(filePath)
          .then((response) => response.text())
          .then((html) => {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            const specificDiv = tempDiv.querySelector("#data-add-content");
            this.dataAddContent = specificDiv.innerHTML;
          });
      }
    } else {
      this.resetAdding();
    }
    this.togglingDataAdd = false;
  }
  async initAdding(type: string) {
    let dataAdd: DataAdd;

    if (type === "symptoms") {
      dataAdd = new AddSymptoms(this.seerlinqApi, this.patient);
    } else if (type === "vitals") {
      dataAdd = new AddVitals(this.seerlinqApi, this.patient);
    } else if (type === "medications") {
      dataAdd = new AddMedication(this.seerlinqApi, this.patient);
    } else if (type === "diags") {
      dataAdd = new AddDiags(this.seerlinqApi, this.patient);
    } else if (type === "labs") {
      dataAdd = new AddLabs(this.seerlinqApi, this.patient);
    } else if (type === "basics") {
      dataAdd = new AddBasics(this.seerlinqApi, this.patient);
    } else if (type === "events") {
      dataAdd = new AddEvents(this.seerlinqApi, this.patient);
    } else if (type === "thresholds") {
      dataAdd = new AddThresholds(this.seerlinqApi, this.patient);
    } else if (type === "rhc") {
      dataAdd = new AddRHC(this.seerlinqApi, this.patient);
    } else if (type === "echo") {
      dataAdd = new AddEcho(this.seerlinqApi, this.patient);
    } else if (type === "exams") {
      dataAdd = new AddOtherExams(this.seerlinqApi, this.patient);
    } else if (type === "ppg") {
      dataAdd = new AddPPG(this.seerlinqApi, this.patient);
    } else if (type === "any") {
      dataAdd = new AddAnyData(this.seerlinqApi, this.patient);
    } else if (type === "patient") {
      await this.seerlinqApi.checkLoggedIn();
      dataAdd = new AddPatient(this.seerlinqApi, null);
    } else if (type === "user") {
      dataAdd = new AddUser(this.seerlinqApi, null);
    } else if (type === "clinic") {
      dataAdd = new AddClinic(this.seerlinqApi, null);
    } else if (type === "physician") {
      dataAdd = new AddPhysician(this.seerlinqApi, null);
    }

    await dataAdd.initEmpty();
    this.dataAdd = dataAdd;
    this.dataInited = true;
  }

  // dark mode

  setTheme() {
    var darkTheme = document.getElementById("dark-mode-css") as HTMLLinkElement;
    if (this.darkMode) {
      darkTheme.disabled = false;
    } else {
      darkTheme.disabled = true;
    }
  }

  async toggleDarkMode() {
    this.darkMode = !this.darkMode;
    localStorage.setItem("darkMode", String(this.darkMode));
    this.setTheme();
  }
}

function main() {
  initSentry();
  initSolid();

  const seerlinqApi = new ApiConnector(apiRoute);
  setupApiClient();

  const app = reactive(new App(seerlinqApi)) as App;
  const rootElement = document.getElementById("root");
  render(() => <AppRoot app={app} />, rootElement);

  app.initIndex();
}

export type PatientTabId =
  | "alerts"
  | "dri"
  | "vitals"
  | "symptoms"
  | "events"
  | "thresholds"
  | "medications"
  | "diags"
  | "labs"
  | "exams"
  | "basics"
  | "ppg"
  | "any"
  | "healthpro";

export type AdminTabId =
  | "clinics"
  | "physicians"
  | "teams"
  | "limiters"
  | "users";

main();
