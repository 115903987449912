import { Show } from "solid-js";
import { medicalOptions } from "../../../constants";
import { AddAnyData } from "../../../dataAdd/AddAnyData";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellRadio } from "../../uiKit/addTable/CellRadio";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellSelect } from "../../uiKit/addTable/CellSelect";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddAnyDataTable(props: { model: AddAnyData }) {
  return (
    <AddTable
      columns={[
        {
          header: "Measured at",
          mandatory: true,
          cell: (item) => (
            <CellDate
              attr="measurement_datetime"
              item={item}
              type="datetime-local"
            />
          ),
        },
        {
          header: "Measurement type",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="measurement_type"
              item={item}
              values={props.model.variables}
              listTitle="Select data to add"
            />
          ),
        },
        {
          header: "Measurement value",
          mandatory: true,
          cell: (item) => (
            <>
              <Show when={!(item.measurement_type in medicalOptions)}>
                <CellText attr="measurement_value" item={item} width={50} />
              </Show>
              <Show when={item.measurement_type in medicalOptions}>
                <CellRadio
                  attr="measurement_value"
                  item={item}
                  values={medicalOptions[item.measurement_type]}
                />
              </Show>
            </>
          ),
        },
        {
          header: "Measurement unit",
          cell: (item) => props.model.getUnits(item.measurement_type, true),
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="comment" item={item} />,
        },
        {
          header: "Remove data row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
      model={props.model}
      canAddRows
      setAllDates="datetime"
    />
  );
}
