import { createMemo } from "solid-js";

export function CellText(props: {
  item: Record<string, any>;
  attr: string;
  type?: "text" | "number";
  width?: number;
  disabled?: boolean;
}) {
  const width = createMemo(() => {
    if (props.width != null) {
      return `${props.width}px`;
    } else if (props.type === "number") {
      return "50px";
    } else {
      return undefined;
    }
  });

  return (
    <input
      type={props.type ?? "text"}
      value={props.item[props.attr] ?? ""}
      onChange={(event) => {
        props.item[props.attr] = event.target.value;
      }}
      style={{ width: width() }}
      disabled={props.disabled}
    />
  );
}
