import { createMemo, Show } from "solid-js";
import {
  deviceHandovers,
  languages,
  races,
  sexes,
  tokenExpiries,
} from "../../constants";
import { AddPatient } from "../../dataAdd/AddPatient";
import { seerlinqRealms, seerlinqStudies } from "../../enumToText";
import { App } from "../../main";
import { PhysiciansModal } from "../modals/PhysiciansModal";
import { UsersModal } from "../modals/UsersModal";
import { ChooseField } from "../uiKit/form/ChooseField";
import { DateField } from "../uiKit/form/DateField";
import { FormRow } from "../uiKit/form/FormRow";
import { RadioField } from "../uiKit/form/RadioField";
import { SelectField } from "../uiKit/form/SelectField";
import { SwitchField } from "../uiKit/form/SwitchField";
import { TextField } from "../uiKit/form/TextField";

export function AddPatientPage(props: { app: App }) {
  props.app.initAdding("patient");

  const dataAdd = createMemo(() => props.app.dataAdd as AddPatient);

  return (
    <Show when={props.app.dataInited && dataAdd() instanceof AddPatient}>
      <Show when={props.app.seerlinqApi.amILevel3}>
        <PhysiciansModal modal={dataAdd().physiciansModal} />
        <UsersModal modal={dataAdd().usersModal} />
      </Show>
      <h2>Add new patient</h2>(
      <span style="color: #bb16a3">
        <strong>*</strong>
      </span>{" "}
      = mandatory)
      <form>
        <table class="invisible-table invisible-table-data-add">
          <FormRow
            label="Patient study"
            data={
              <SelectField
                attr="patient_study"
                model={dataAdd().addingList}
                values={seerlinqStudies}
                listTitle="Select study"
                listValue
                intValue
              />
            }
            mandatory
          />

          <FormRow
            label="Date of Birth"
            data={
              <DateField
                type="date"
                attr="date_of_birth"
                model={dataAdd().addingList}
              />
            }
            mandatory
          />

          <FormRow
            label="Sex"
            data={
              <RadioField
                attr="sex"
                model={dataAdd().addingList}
                values={sexes}
              />
            }
            mandatory
          />

          <FormRow
            label="Race"
            data={
              <SelectField
                attr="race"
                model={dataAdd().addingList}
                values={races}
              />
            }
            mandatory
          />

          <FormRow
            label="Height [cm]"
            data={<TextField attr="height" model={dataAdd().addingList} />}
          />

          <FormRow
            label="Description"
            data={
              <TextField
                attr="description"
                model={dataAdd().addingList}
                width={300}
              />
            }
          />

          <FormRow
            label="Monitoring realm"
            data={
              <SelectField
                attr="realm"
                model={dataAdd().addingList}
                values={seerlinqRealms}
                listTitle="Select realm"
              />
            }
            mandatory
          />

          <FormRow
            label="Create user (for the app)"
            data={
              <SwitchField
                attr="createUser"
                model={dataAdd()}
                truthy="Yes"
                falsy="No"
              />
            }
            mandatory
          />

          <FormRow
            label="Name"
            data={<TextField attr="name" model={dataAdd().addingList} />}
            mandatory
          />

          <FormRow
            label="Email"
            data={<TextField attr="email" model={dataAdd().addingList} />}
            mandatory
          />

          <Show when={dataAdd().createUser}>
            <FormRow
              label="Device handover (changes welcome email)"
              data={
                <RadioField
                  attr="welcome_email_type"
                  model={dataAdd().addingList}
                  values={deviceHandovers}
                />
              }
              mandatory
            />
          </Show>

          <FormRow
            label="Phone"
            data={<TextField attr="phone" model={dataAdd().addingList} />}
          />

          <FormRow
            label="Residence"
            data={<TextField attr="residence" model={dataAdd().addingList} />}
          />

          <FormRow
            label="Health insurance provider"
            data={
              <TextField attr="health_insurance" model={dataAdd().addingList} />
            }
            mandatory
          />

          <FormRow
            label="ID number"
            data={<TextField attr="id_number" model={dataAdd().addingList} />}
            mandatory
          />

          <Show when={dataAdd().createUser}>
            <FormRow
              label="Preferred language"
              data={
                <SelectField
                  attr="preferred_language"
                  model={dataAdd().addingList}
                  values={languages}
                />
              }
              mandatory
            />

            <FormRow
              label="Finish registration link expiration"
              data={
                <SelectField
                  attr="token_expiry_timedelta"
                  model={dataAdd().addingList}
                  values={tokenExpiries}
                />
              }
              mandatory
            />
          </Show>

          <Show when={props.app.seerlinqApi.amILevel3}>
            <FormRow
              label="Add patient to physicians"
              data={
                <ChooseField
                  value={`${dataAdd().physiciansLength()} chosen`}
                  onClick={() => {
                    dataAdd().choosePhysiciansForPatient();
                  }}
                />
              }
            />

            <FormRow
              label={
                <>
                  Add patient to users in <strong>physician</strong> role
                </>
              }
              data={
                <ChooseField
                  value={`${dataAdd().usersLength()} chosen`}
                  onClick={() => {
                    dataAdd().chooseUsersForPatient();
                  }}
                />
              }
            />
          </Show>
        </table>
      </form>
      <br />
      <br />
      <button
        type="button"
        onClick={() => {
          dataAdd().post(
            dataAdd().physiciansModal.finalMultiChoice,
            dataAdd().usersModal.finalMultiChoice,
          );
        }}
      >
        Add patient
      </button>
    </Show>
  );
}
