import { reactive } from "@vue/reactivity";
import { deviceHandovers, languages, tokenExpiries } from "../../constants";
import { seerlinqStudies } from "../../enumToText";
import { Patient } from "../../patient";
import { FormRow } from "../uiKit/form/FormRow";
import { RadioField } from "../uiKit/form/RadioField";
import { SelectField } from "../uiKit/form/SelectField";
import { TextField } from "../uiKit/form/TextField";

export function CreateUserForm(props: { patient: Patient }) {
  const data = reactive({
    token_expiry_timedelta: "P1D",
    preferred_language: "sk",
    new_patient_study: [0],
  });

  return (
    <div>
      <table class="invisible-table invisible-table-data-add">
        <FormRow
          label="Email (also used as username)"
          data={<TextField attr="email" model={data} />}
        />

        <FormRow
          label="Device handover (changes welcome email)"
          data={
            <RadioField
              attr="welcome_email_type"
              model={data}
              values={deviceHandovers}
            />
          }
        />

        <FormRow
          label="Patient study"
          data={
            <SelectField
              attr="new_patient_study"
              model={data}
              values={seerlinqStudies}
              listValue
            />
          }
        />

        <FormRow
          label="Preferred language"
          data={
            <SelectField
              attr="preferred_language"
              model={data}
              values={languages}
            />
          }
        />

        <FormRow
          label="Finish registration link expiration"
          data={
            <SelectField
              attr="token_expiry_timedelta"
              model={data}
              values={tokenExpiries}
            />
          }
        />
      </table>

      <br />

      <button
        type="button"
        onClick={() => {
          props.patient.createConnectedUser(data);
        }}
      >
        Create user
      </button>
    </div>
  );
}
