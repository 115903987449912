import { AddPPG } from "../../../dataAdd/AddPPG";
import { ppgConditions, ppgMeasModes, ppgQuality } from "../../../enumToText";
import { Patient } from "../../../patient";
import { DateField } from "../../uiKit/form/DateField";
import { SelectField } from "../../uiKit/form/SelectField";
import { TextField } from "../../uiKit/form/TextField";

export function AddPPGForm(props: { model: AddPPG; patient: Patient }) {
  return (
    <div>
      <hr />
      <div>
        <h3>{props.model.desc}</h3>(
        <span style="color: #bb16a3">
          <strong>*</strong>
        </span>{" "}
        = mandatory)
        <form>
          <table class="invisible-table invisible-table-data-add">
            <tbody>
              <tr>
                <td class="left-column">Patient HF study ID:</td>
                <td class="right-column">{props.patient.patientId}</td>
              </tr>
              <tr>
                <td class="left-column">
                  Measured at{" "}
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <DateField
                    attr="measurement_datetime"
                    model={props.model.addingList}
                    type="datetime-local"
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  Measurement condition
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <SelectField
                    attr="measurement_condition"
                    model={props.model.addingList}
                    values={ppgConditions}
                    listTitle="Select condition"
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  Measurement device
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <TextField
                    attr="measurement_device"
                    model={props.model.addingList}
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  Measurement origin
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <TextField
                    attr="measurement_origin"
                    model={props.model.addingList}
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">Measurement mode:</td>
                <td class="right-column">
                  <SelectField
                    attr="measurement_mode"
                    model={props.model.addingList}
                    values={ppgMeasModes}
                    intValue
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">Quality flag:</td>
                <td class="right-column">
                  <SelectField
                    attr="quality_flag"
                    model={props.model.addingList}
                    values={ppgQuality}
                    intValue
                  />
                </td>
              </tr>
              <tr>
                <td class="left-column">Comment:</td>
                <td class="right-column">
                  <TextField attr="comment" model={props.model.addingList} />
                </td>
              </tr>
              <tr>
                <td class="left-column">
                  PPG file (.csv)
                  <span style="color: #bb16a3">
                    <strong>*</strong>
                  </span>
                  :
                </td>
                <td class="right-column">
                  <input
                    type="file"
                    onChange={(event) => {
                      props.model.uploadFile(event);
                    }}
                    accept="text/csv"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <button
            type="button"
            onClick={() => {
              props.model.post();
            }}
          >
            Post
          </button>
        </form>
      </div>
      <hr />
    </div>
  );
}
