import { MedicationDto } from "../apiConnector";
import { DataModel } from "./DataModel";
import { dayNames } from "../dataUtils";

export class Medications extends DataModel<MedicationDto> {
  switched = false;
  switchedOrder = [1, 2, 0];
  origOrder = [2, 0, 1];

  // constants
  changeDosageEditable = [
    "medication_dose",
    "medication_unit",
    "medication_dosage",
  ];
  changeId = null;
  changeDosageField: any = {
    medication_dosage: [],
    medication_change_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
  };

  constructor(data: MedicationDto[], canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["medication_started", "medication_ended", "medication_name"];
    this.sortByNames = ["Started", "Ended", "Name"];
    this.dateFields = ["medication_started", "medication_ended"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["medication_name"];
    this.editable = [
      "medication_started",
      "medication_ended",
      "medication_dose",
      "medication_unit",
      "medication_dosage",
      "comment",
    ];
    this.filteringAttrs = ["medication_group"];
    this.initSort = [true, null, null];
    this.canSwitchSortOrder = true;
  }

  canEditName(member) {
    if (member.medication_group === "Other") {
      return true;
    } else {
      return false;
    }
  }

  freqDays(member) {
    return member.medication_frequency_on_days
      .map((day) => dayNames[day])
      .join("/");
  }

  freqString(member) {
    const ordinalSuperscript = { 1: "st", 2: "nd", 3: "rd" };
    if (member.medication_frequency_regular_repeat_every != null) {
      const ordinalStr =
        ordinalSuperscript[
          parseInt(member.medication_frequency_regular_repeat_every)
        ] || "th";
      return (
        "Every " +
        member.medication_frequency_regular_repeat_every +
        ordinalStr +
        " day"
      );
    } else {
      return "Days: " + this.freqDays(member);
    }
  }

  customSwitch() {
    if (this.switched) {
      this.switchSortOrder(this.switchedOrder);
    } else {
      this.switchSortOrder(this.origOrder);
    }
    this.switched = !this.switched;
  }

  getDosageChangeField(index) {
    const member = this.rawData[index];
    var editedField = Object.keys(member)
      .filter(
        (key) =>
          this.changeDosageEditable.length === 0 ||
          this.changeDosageEditable.includes(key),
      )
      .reduce((obj, key) => {
        obj[key] = member[key];
        return obj;
      }, {});
    editedField["medication_change_date"] = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");

    return editedField;
  }

  startChangingDosage(index) {
    this.startEditing(index, [
      "medication_dose",
      "medication_unit",
      "medication_dosage",
    ]);

    this.editedField["medication_change_date"] = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
  }

  stopChangingDosage() {
    this.changeId = null;
    this.changeDosageField = {
      medication_dosage: [],
      medication_change_date: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
    };
  }
}
