import { Show } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

export function CellEditButtons(props: {
  model: DataModel;
  index: number;
  onConfirm: (editedField: any) => void;
  onCleanup?: () => void;
}) {
  return (
    <Show
      when={props.model.editingId === props.index}
      fallback={
        <button
          onClick={() => {
            props.model.startEditing(props.index);
          }}
        >
          Edit
        </button>
      }
    >
      <button
        onClick={() => {
          props.onConfirm(props.model.editedField);
          props.onCleanup?.();
          props.model.stopEditing();
        }}
      >
        Update
      </button>{" "}
      <button
        onClick={() => {
          props.model.stopEditing();
          props.onCleanup?.();
        }}
      >
        X
      </button>
    </Show>
  );
}
