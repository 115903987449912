import { Show } from "solid-js";
import { ModalWindow } from "../../../modals";

export function CellSelectRow(props: { model: ModalWindow; id: string }) {
  return (
    <>
      <Show when={props.model.chooseSingle}>
        <input
          type="radio"
          checked={props.model.finalSingleChoice === props.id}
          onChange={() => {
            props.model.finalSingleChoice = props.id;
          }}
        />
      </Show>

      <Show when={!props.model.chooseSingle}>
        <input
          type="checkbox"
          checked={props.model.finalMultiChoice.includes(props.id)}
          onChange={() => {
            props.model.toggleMultiChoice(props.id);
          }}
        />
      </Show>
    </>
  );
}
