import { JSX } from "solid-js";

export function FormRow(props: {
  label: JSX.Element;
  data: JSX.Element;
  mandatory?: boolean;
}) {
  return (
    <tr>
      <td class="left-column">
        {props.label}{" "}
        {props.mandatory && (
          <span style="color: #bb16a3">
            <strong>*</strong>
          </span>
        )}
        :
      </td>
      <td class="right-column column-max-width">{props.data}</td>
    </tr>
  );
}
