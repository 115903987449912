export class ModalWindow {
  modalOpen = false;
  chooseSingle = true;
  finalSingleChoice = null;
  finalMultiChoice = [];
  modalChange = false;
  editingIndex = null;
  modalText = "";

  constructor(public modalData: any) {}

  closeModal() {
    this.modalOpen = false;
    document.body.style.overflow = "auto";
  }

  openModal() {
    this.modalOpen = true;
    this.modalChange = true;
    document.body.style.overflow = "hidden";
  }

  resetModal() {
    this.chooseSingle = true;
    this.finalSingleChoice = null;
    this.finalMultiChoice = [];
    this.editingIndex = null;
    this.modalText = "";
  }

  toggleMultiChoice(option: any) {
    if (this.finalMultiChoice.includes(option)) {
      this.finalMultiChoice = this.finalMultiChoice.filter(
        (opt) => opt !== option,
      );
    } else {
      this.finalMultiChoice.push(option);
    }
  }

  isSelected(option: any) {
    if (this.chooseSingle && this.finalSingleChoice === option) {
      return true;
    }
    if (!this.chooseSingle && this.finalMultiChoice.includes(option)) {
      return true;
    }
    return false;
  }

  setSingleChoice(index: number, initChoice: any, text: string) {
    this.finalSingleChoice = this.finalSingleChoice
      ? this.finalSingleChoice
      : initChoice;
    this.modalText = text;
    this.editingIndex = index;
    this.openModal();
  }

  setMultiChoice(index: number, initChoice: any, text: string) {
    this.finalMultiChoice =
      this.finalMultiChoice.length > 0 ? this.finalMultiChoice : initChoice;
    this.modalText = text;
    this.chooseSingle = false;
    this.editingIndex = index;
    this.openModal();
  }

  async selectAll(field: string) {
    const filtered = await this.modalData.sortFilterData();
    this.finalMultiChoice = filtered.map((obj: any) => obj[field]);
  }
}
