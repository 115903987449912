import { createMemo, For } from "solid-js";

export function SelectField(props: {
  model: Record<string, any>;
  attr: string;
  values: Record<string | number, string> | string[];
  intValue?: boolean;
  /** Indicates that the value is a list (this is implicit when using `multiple`) */
  listValue?: boolean;
  listTitle?: string;
  multiple?: boolean;
}) {
  const value = createMemo(() => props.model[props.attr]);

  return (
    <select
      value={props.multiple ? undefined : value()}
      onChange={(event) => {
        let value: unknown;

        if (props.listValue || props.multiple) {
          value = Array.from(event.target.selectedOptions).map((opt) =>
            props.intValue ? parseInt(opt.value) : opt.value,
          );
        } else {
          value = props.intValue
            ? parseInt(event.target.value)
            : event.target.value;
        }

        props.model[props.attr] = value;
      }}
      multiple={props.multiple}
    >
      {props.listTitle && (
        <option value="null" disabled>
          {props.listTitle}
        </option>
      )}

      <For
        each={
          Array.isArray(props.values) ? props.values : Object.keys(props.values)
        }
      >
        {(i) => (
          <option value={i}>
            {Array.isArray(props.values) ? i : props.values[i]}
          </option>
        )}
      </For>
    </select>
  );
}
