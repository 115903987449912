import {
  apiGetAuthLogout,
  apiGetInfo,
  apiGetLimiters,
  apiGetMonitoringTeams,
  apiGetUsersMe,
  APIInfoResponse,
  apiPostAuthLogin,
  apiPutPatientsPatientIdApproveConsentPaper,
  apiPutPatientsPatientIdApproveConsentTelemonitoring,
  apiPutPatientsPatientIdRevokeConsentPaper,
  apiPutPatientsPatientIdRevokeConsentTelemonitoring,
  client,
  DefaultMedicationSchemaResponse,
  FullUserResponse,
  Level3PatientResponse,
  PatientResponse,
  UserPatientRoleResponse,
  UserResponseManaging,
} from "./api";
import { apiRoute } from "./config";

// basic settings
const rolesLevel3 = ["admin", "seerlinq-user", "study-physician"];
const rolesCanAddPatients = [
  "physician",
  "study-physician",
  "seerlinq-user",
  "admin",
];

export class ApiConnector {
  apiRoute: string;
  apiVersion: string;
  loggedIn: boolean;
  username: string | null;
  apiInfo: APIInfoResponse;
  amIAdmin: boolean;
  canIAddPatients: boolean;
  amILevel3: boolean;
  isProdEnv: boolean;

  constructor(apiRoute: string, apiVersion: string = "v1") {
    this.apiRoute = apiRoute;
    this.apiVersion = apiVersion;
    this.loggedIn = false;
    this.username = null;
    this.apiInfo = null;
    this.amIAdmin = false;
    this.canIAddPatients = false;
    this.amILevel3 = false;
    this.isProdEnv = false;
    console.log("Initialized with route", apiRoute);
  }

  //   general functions

  async rawRequest(
    endpoint,
    method,
    headers,
    body = null,
    stringifyBody = true,
    baseUrl: string = null,
  ) {
    if (baseUrl == null) {
      var baseUrl = `${this.apiRoute}/api/${this.apiVersion}`;
    }
    const url = `${baseUrl}/${endpoint}`;
    let finalBody;

    if (body) {
      if (stringifyBody) {
        finalBody = JSON.stringify(body);
      } else {
        finalBody = body;
      }
    } else {
      finalBody = null;
    }
    const options: RequestInit = {
      method: method,
      headers: headers,
      credentials: "include",
      body: finalBody,
    };
    try {
      const response = await fetch(url, options);
      return response;
    } catch (error) {
      console.error("API error:", error);
      return null;
    }
  }

  async request(
    endpoint,
    method,
    headers,
    body = null,
    stringifyBody = true,
    baseUrl = null,
  ) {
    const response = await this.rawRequest(
      endpoint,
      method,
      headers,
      body,
      stringifyBody,
      baseUrl,
    );
    if (!response.ok) {
      const errorMessage = `Calling ${endpoint} returned status: ${response.status}`;
      const errorDetails = await response.text();
      const formattedMessage = `API error\n ${errorMessage}\nDetails: ${errorDetails}`;
      console.error(formattedMessage);
      alert(formattedMessage);
      return null;
    }
    if (method === "DELETE") {
      var data = null;
    } else {
      var data = await response.json();
    }

    return data;
  }

  async get(endpoint: string) {
    return await this.request(endpoint, "GET", {}, null);
  }

  async post(endpoint: string, body: object) {
    const headers = await this.postPutHeaders();
    return await this.request(endpoint, "POST", headers, body);
  }

  async put(endpoint: string, body?: object) {
    const headers = await this.postPutHeaders();
    return await this.request(endpoint, "PUT", headers, body);
  }

  async delete(endpoint: string) {
    const headers = await this.postPutHeaders();
    return await this.request(endpoint, "DELETE", headers, null);
  }

  // headers

  async postPutHeaders() {
    const csrfToken = getCSRFToken("csrf_access_token");
    return {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": csrfToken,
    };
  }

  async refreshHeaders() {
    const csrfToken = getCSRFToken("csrf_refresh_token");
    return { "X-CSRF-TOKEN": csrfToken };
  }

  // auth flow

  async login(username: string, password: string) {
    const loginBody = {
      username: username,
      password: password,
    };
    const data = await apiPostAuthLogin({
      body: loginBody,
      throwOnError: true,
    });
    if (data) {
      console.log("Logged in");
      await this.checkLoggedIn();
      window.location.href = "/";
    }
  }

  async setLoggedIn(
    data: UserPatientRoleResponse | UserResponseManaging | FullUserResponse,
  ) {
    this.loggedIn = true;
    this.username = data.username;
    const info = await apiGetInfo({ throwOnError: true });
    this.apiInfo = info.data;
    this.amIAdmin = "admin" === data.role;
    this.amILevel3 = rolesLevel3.includes(data.role);
    this.isProdEnv = this.apiInfo.environment === "prod";
    this.canIAddPatients = rolesCanAddPatients.includes(data.role);
  }

  async logout() {
    await apiGetAuthLogout({ throwOnError: true });
    console.log("Logged out");
    this.setLoggedOut();
  }

  setLoggedOut() {
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }

  async refresh() {
    const headers = await this.refreshHeaders();
    return await this.rawRequest("auth/refresh", "GET", headers, null);
  }

  async getMe() {
    const myself = await apiGetUsersMe({ throwOnError: false });
    return myself;
  }

  async checkLoggedIn(tryRefresh: boolean = true) {
    let me = await this.getMe();
    if (me.response.status === 200) {
      await this.setLoggedIn(me.data);
    } else if (me.response.status === 401 && tryRefresh) {
      let refresh_data = await this.refresh();
      if (refresh_data.status === 200) {
        let me = await this.getMe();
        if (me.response.status === 200) {
          await this.setLoggedIn(me.data);
          console.log("Token refreshed");
        } else {
          this.setLoggedOut();
        }
      } else {
        this.setLoggedOut();
      }
    }
  }

  // API operations

  async getLimiters() {
    const limiters = await apiGetLimiters({ throwOnError: true });
    return limiters.data;
  }

  async getMonitoringTeams() {
    const teams = await apiGetMonitoringTeams({ throwOnError: true });
    return teams.data;
  }

  async revokePaperConsent(patId: number) {
    await apiPutPatientsPatientIdRevokeConsentPaper({
      path: { patient_id: patId },
      throwOnError: true,
    });
    window.location.reload();
  }

  async revokeTeleConsent(patId: number) {
    await apiPutPatientsPatientIdRevokeConsentTelemonitoring({
      path: { patient_id: patId },
      throwOnError: true,
    });
    window.location.reload();
  }

  async approvePaperConsent(patId: number) {
    await apiPutPatientsPatientIdApproveConsentPaper({
      path: { patient_id: patId },
      throwOnError: true,
    });
    window.location.reload();
  }

  async approveTeleConsent(patId: number) {
    await apiPutPatientsPatientIdApproveConsentTelemonitoring({
      path: { patient_id: patId },
      throwOnError: true,
    });
    window.location.reload();
  }
}

function getCSRFToken(token: string) {
  let csrfToken = null;
  const cookies = document.cookie.split(";");
  cookies.forEach((cookie) => {
    const [name, value] = cookie.trim().split("=");
    if (name === token) {
      csrfToken = decodeURIComponent(value);
    }
  });
  return csrfToken !== null ? csrfToken : null;
}

export function setupApiClient() {
  client.setConfig({
    baseUrl: apiRoute,
    credentials: "include",
  });

  // Add CSRF token for POST/PUT
  client.interceptors.request.use((request) => {
    if (
      request.method === "POST" ||
      request.method === "PUT" ||
      request.method === "DELETE"
    ) {
      const token = getCSRFToken("csrf_access_token");
      if (token) {
        request.headers.set("X-CSRF-TOKEN", getCSRFToken("csrf_access_token"));
      }
    }
    return request;
  });

  client.interceptors.response.use(async (response, request, options) => {
    if (!response.ok && options.throwOnError) {
      const errorMessage = `Calling ${options.method} ${options.url} returned status: ${response.status}`;
      const errorDetails = await response.text();
      const formattedMessage = `API error\n ${errorMessage}\nDetails: ${errorDetails}`;
      console.error(formattedMessage);
      alert(formattedMessage);
    }
    return response;
  });
}

/** This is the type we get from the `/patients` endpoint when we use `load_type: basic`. */
export type PatientDto = Level3PatientResponse | PatientResponse;

export type MedicationDto = DefaultMedicationSchemaResponse;
