import { For, Show } from "solid-js";
import { AddThresholds } from "../../../dataAdd/AddThresholds";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellSelect } from "../../uiKit/addTable/CellSelect";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddThresholdsTable(props: { model: AddThresholds }) {
  return (
    <AddTable
      model={props.model}
      infoExtra={
        <>
          <div class="text-red">
            Only one threshold per patient, per variable, per trigger, per type,
            and per threshold settings can exist. When you want to update a
            specific threshold, do it in the table below.
          </div>
          <div>
            <strong>Value-based thresholds</strong>: Alerts are triggered when
            the variable is above/below (based on the threshold type) at least
            <em>X</em> times out of <em>Y</em>.<br />
            <strong>Change-based thresholds</strong>: Alerts are triggered when
            the variable increased/decreased (based on change type) over{" "}
            <em>X</em> day(s) (while taking into an account <em>N</em> maximum
            measurements). When defining threshold for <strong>decrease</strong>
            , the value must be negative; if you insert positive number, it is
            automatically treated as negative.
          </div>
          <br />
        </>
      }
      columns={[
        {
          header: "Table:",
          cell: (item) =>
            props.model.threshTables[
              props.model.availableThresholds[item.threshold_variable]
            ],
        },
        {
          header: "Variable",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="threshold_variable"
              item={item}
              values={Object.keys(props.model.availableThresholds)}
              listTitle="Select variable"
            />
          ),
        },
        {
          header: "Type",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="threshold_type"
              item={item}
              values={props.model.threshTypes}
              listTitle="Select type"
              disabled={item.threshold_trigger === "change_based"}
            />
          ),
        },
        {
          header: "Trigger",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="threshold_trigger"
              item={item}
              values={props.model.threshTriggers}
              listTitle="Select trigger"
              onChange={() => {
                props.model.initSettings(item);
              }}
            />
          ),
        },
        {
          header: "Settings",
          mandatory: true,
          cell: (item) => (
            <>
              <Show when={!item.threshold_settings}>--</Show>
              <Show
                when={
                  item.threshold_settings &&
                  item.threshold_settings.threshold_trigger === "value_based"
                }
              >
                <input
                  style="width: 25px"
                  type="number"
                  value={item.threshold_settings.at_least}
                  onChange={(event) => {
                    item.threshold_settings.at_least = event.target.value;
                  }}
                />
                <span> out of </span>
                <input
                  style="width: 25px"
                  type="number"
                  value={item.threshold_settings.out_of}
                  onChange={(event) => {
                    item.threshold_settings.out_of = event.target.value;
                  }}
                />
              </Show>
              <Show
                when={
                  item.threshold_settings &&
                  item.threshold_settings.threshold_trigger === "change_based"
                }
              >
                <select
                  value={item.threshold_settings.change_type}
                  onChange={(event) => {
                    const value = event.target.value;
                    item.threshold_settings.change_type = value;
                    item.threshold_type = props.model.changeToThreshType[value];
                  }}
                >
                  <option value="null" disabled>
                    Select change
                  </option>
                  <For each={props.model.changeTypes}>
                    {(change) => <option value={change}>{change}</option>}
                  </For>
                </select>
                <span> over </span>
                <input
                  style="width: 25px"
                  type="number"
                  value={item.threshold_settings.look_back_days}
                  onChange={(event) => {
                    item.threshold_settings.look_back_days = event.target.value;
                  }}
                />
                <span> day(s) (max </span>
                <input
                  style="width: 25px"
                  type="number"
                  value={item.threshold_settings.maximum_measurements}
                  onChange={(event) => {
                    item.threshold_settings.maximum_measurements =
                      event.target.value;
                  }}
                />
                <span> measurements)</span>
              </Show>
            </>
          ),
        },
        {
          header: "Value",
          mandatory: true,
          cell: (item) => (
            <CellText attr="threshold_value" item={item} width={50} />
          ),
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="threshold_comment" item={item} />,
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
    />
  );
}
