import clsx from "clsx";
import { JSX } from "solid-js";

export function Tab(props: {
  children?: JSX.Element;
  active?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      class={clsx("modal-tab", props.active && "active")}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
