import { For } from "solid-js";

export function CellRadio(props: {
  attr: string;
  item: Record<string, any>;
  values: string[] | number[];
}) {
  return (
    <For each={props.values}>
      {(value) => (
        <label>
          <span>{value}:</span>{" "}
          <input
            type="radio"
            value={value}
            checked={props.item[props.attr] === value}
            onChange={(event) => {
              if (event.target.checked) {
                props.item[props.attr] = value;
              }
            }}
          />{" "}
        </label>
      )}
    </For>
  );
}
