import { Show } from "solid-js";
import { ModalWindow } from "../../../modals";

export function HeaderSelectRows(props: {
  model: ModalWindow;
  idAttr: string;
}) {
  return (
    <Show when={!props.model.chooseSingle}>
      <button
        onClick={() => {
          props.model.selectAll(props.idAttr);
        }}
      >
        All
      </button>
      <button
        onClick={() => {
          props.model.finalMultiChoice = [];
        }}
      >
        None
      </button>
    </Show>
  );
}
