import { createMemo, Show } from "solid-js";
import { AdminConsole } from "../../adminUtils";
import { connectedRoles, languages, managingRoles } from "../../constants";
import { AddUser } from "../../dataAdd/AddUser";
import { App } from "../../main";
import { PatientsModal } from "../modals/PatientsModal";
import { TemporaryPasswordDialog } from "../patient/show/TemporaryPasswordDialog";
import { ChooseField } from "../uiKit/form/ChooseField";
import { FormRow } from "../uiKit/form/FormRow";
import { SelectField } from "../uiKit/form/SelectField";
import { TextField } from "../uiKit/form/TextField";
import { CellChoose } from "../uiKit/table/CellChoose";
import { CellEditButtons } from "../uiKit/table/CellEditButtons";
import { CellSelectEditable } from "../uiKit/table/CellSelectEditable";
import { HeaderSelectFilter } from "../uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "../uiKit/table/HeaderSort";
import { HeaderTextFilter } from "../uiKit/table/HeaderTextFilter";
import { Table } from "../uiKit/table/Table";

export function UsersAdmin(props: { admin: AdminConsole; app: App }) {
  const model = createMemo(() => props.admin.users);
  const dataAdd = createMemo(() => props.app.dataAdd as AddUser);

  return (
    <div id="modal-content">
      <TemporaryPasswordDialog admin={props.admin} />
      <PatientsModal modal={props.admin.patientsModal} />

      <h2>Users</h2>
      <span>
        We have <strong>{props.admin.users.data.length}</strong> user(s) in the
        database.
      </span>

      <br />
      <br />

      <button
        onClick={() => {
          props.admin.toggleCreateUser();
        }}
      >
        {props.admin.addingUser ? "Cancel" : "Create new user"}
      </button>

      <br />
      <br />

      <Show when={!props.admin.addingUser}>
        <Table
          columns={[
            {
              header1: "Username",
              header2: <HeaderSort attr="username" model={model()} />,
              header3: (
                <HeaderTextFilter model={model()} placeholder="username..." />
              ),
              cell: (item) => <strong>{item.username}</strong>,
            },
            {
              header1: "Role",
              header2: (
                <HeaderSelectFilter attr="role" model={model()} name="Role" />
              ),
              cell: (item) => item.role,
            },
            {
              header1: "Active",
              header2: (
                <HeaderSelectFilter
                  attr="active"
                  model={model()}
                  name="Active"
                />
              ),
              cell: (item) => String(item.active),
            },
            {
              header1: "Monitoring team",
              header2: (
                <HeaderSelectFilter
                  attr="monitoring_team"
                  model={model()}
                  name="Team"
                />
              ),
              cell: (item) => item.monitoring_team,
            },
            {
              header1: "Added",
              header2: <HeaderSort attr="created_at" model={model()} />,
              cell: (item) => item.created_at,
            },
            {
              header1: "Added by",
              header2: (
                <HeaderSelectFilter
                  attr="added_by"
                  model={model()}
                  name="Who added"
                  values={props.admin.users.userMapping}
                />
              ),
              cell: (item) => props.admin.users.userMapping[item.added_by],
            },
            {
              header1: "Last login",
              cell: (item) => item.last_login,
            },
            {
              header1: "Preferred language",
              cell: (item, index) => (
                <CellSelectEditable
                  attr="preferred_language"
                  index={index}
                  model={model()}
                  values={languages}
                />
              ),
            },
            {
              header1: "Connected ID",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  onClick={() => {
                    props.admin.editConnectedPatientForUser(index);
                  }}
                  valueEdit={props.admin.currentConnectedPatientsForUser(index)}
                  valueView={
                    <strong>
                      <a
                        style="color: #bb16a3"
                        href={`/patient/${item.connected_patient_id}`}
                      >
                        {item.connected_patient_id}
                      </a>
                    </strong>
                  }
                  disabled={!connectedRoles.includes(item.role)}
                />
              ),
            },
            {
              header1: "Managed IDs",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  onClick={() => {
                    props.admin.editManagingPatientsForUser(index);
                  }}
                  valueEdit={
                    <Show when={managingRoles.includes(item.role)}>
                      {props.admin.numberOfManagingPatients(index)}
                    </Show>
                  }
                  valueView={
                    <span
                      innerHTML={props.admin.users.generateLinks(
                        item.managed_patients.map(
                          (patient) => patient.patient_id,
                        ),
                      )}
                    />
                  }
                  disabled={!managingRoles.includes(item.role)}
                />
              ),
            },
            {
              header1: "# of monitored patients",
              cell: (item) =>
                item.monitoring_patients ? item.monitoring_patients.length : "",
            },
            props.app.seerlinqApi.amIAdmin && {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updateUser(item.uuid, editedField);
                  }}
                  onCleanup={() => {
                    props.admin.resetModals();
                  }}
                />
              ),
            },
            props.app.seerlinqApi.amIAdmin && {
              header1: "Reset user",
              cell: (item) => (
                <Show when={connectedRoles.includes(item.role)} fallback="--">
                  <div>
                    <button
                      data-tooltip="Send 'forgot password' reset email."
                      onClick={() => {
                        props.admin.resetUser(item.uuid);
                      }}
                    >
                      Reset user
                    </button>
                  </div>
                </Show>
              ),
            },
            props.app.seerlinqApi.amIAdmin && {
              header1: "Temporary password",
              cell: (item) => (
                <Show when={connectedRoles.includes(item.role)} fallback="--">
                  <div>
                    <button
                      data-tooltip={props.admin.tempPasswordTooltipText(item)}
                      disabled={item.has_temporary_password}
                      onClick={() => {
                        props.admin.temporaryPassword(item.uuid);
                      }}
                    >
                      Temp. password
                    </button>
                  </div>
                </Show>
              ),
            },
          ]}
          model={model()}
          sticky
        />
      </Show>

      <Show
        when={
          props.admin.addingUser &&
          props.app.seerlinqApi.amIAdmin &&
          props.app.dataInited &&
          dataAdd() instanceof AddUser
        }
      >
        <div>
          <h3>Add new user</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Username (case INsensitive)"
                data={
                  <TextField attr="username" model={dataAdd().addingList} />
                }
              />

              <FormRow
                label="Email"
                data={<TextField attr="email" model={dataAdd().addingList} />}
              />

              <FormRow
                label="Password"
                data={
                  <TextField
                    attr="password"
                    model={dataAdd().addingList}
                    type="password"
                  />
                }
              />

              <FormRow
                label="Role"
                data={
                  <SelectField
                    attr="role"
                    values={dataAdd().allRoles}
                    model={dataAdd().addingList}
                    listTitle="Select role"
                  />
                }
              />

              <FormRow
                label="Preferred language"
                data={
                  <SelectField
                    attr="preferred_language"
                    values={languages}
                    model={dataAdd().addingList}
                  />
                }
              />

              <Show when={connectedRoles.includes(dataAdd().addingList.role)}>
                <FormRow
                  label="Connected patient"
                  data={
                    <ChooseField
                      onClick={() => {
                        props.admin.chooseConnectedPatientForUser();
                      }}
                      value={
                        props.admin.patientsModal.finalSingleChoice
                          ? props.admin.patientsModal.finalSingleChoice
                          : "None"
                      }
                    />
                  }
                />
              </Show>

              <Show when={managingRoles.includes(dataAdd().addingList.role)}>
                <FormRow
                  label="Managing patients"
                  data={
                    <ChooseField
                      onClick={() => {
                        props.admin.chooseManagingPatientsForUser();
                      }}
                      value={`${props.admin.patientsModal.finalMultiChoice.length} chosen`}
                    />
                  }
                />
              </Show>
            </table>

            <br />
            <br />

            <button
              type="button"
              onClick={() => {
                dataAdd().post(
                  props.admin.patientsModal.finalSingleChoice,
                  props.admin.patientsModal.finalMultiChoice,
                );
              }}
            >
              Add user
            </button>
          </form>
        </div>
      </Show>

      <br />
      <div>
        <p>User roles:</p>
        <table class="info-table" id="user-class">
          <tbody>
            <tr>
              <td>
                <strong>Level 1</strong> | <em>self-only</em>
              </td>
              <td>
                <strong>patient-ppg-app</strong>
              </td>
              <td>
                Only create PPG data and only sees its
                <em>connected patient ID</em>. This is typically user for app
                that has patient at home.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 1</strong> | <em>managed</em>
              </td>
              <td>
                <strong>physician-ppg-app</strong>
              </td>
              <td>
                Only create PPG data and can see its
                <em>managed patient IDs</em>. This can be app that some
                physician might have at their office so they can measure their
                patients.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 1</strong> | <em>full</em>
              </td>
              <td>
                <strong>study-ppg-app</strong>
              </td>
              <td>
                Only create PPG data and can see <em>all patient IDs</em>. This
                is SmartCare app at Premedix Office, so we can measure any
                patient.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 2</strong> | <em>self-only</em>
              </td>
              <td>
                <strong>patient</strong>
              </td>
              <td>
                Limited read/write for any data, can update data they created.
                Only sees its <em>connected patient ID</em>. This is user for
                Seerlinq phone app for submitting basic patient and medial data.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 2</strong> | <em>managed</em>
              </td>
              <td>
                <strong>physician</strong>
              </td>
              <td>
                Limited read/write for any data, can update data they created.
                It can see their <em>managed patient IDs</em>. This is user for
                any physician that monitors their patients.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 3</strong> | <em>managed</em>
              </td>
              <td>
                <strong>study-physician</strong>
              </td>
              <td>
                Full read/write for any data, can update data they created. It
                can see their <em>managed patient IDs</em>. This is our
                related-physician (think Amitai) that can see all and any data,
                run algorithms, but still sees only their patients.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 3</strong> | <em>full</em>
              </td>
              <td>
                <strong>seerlinq-user</strong>
              </td>
              <td>
                Full read/write for any data, can update data they created. It
                can see
                <em>all patient IDs</em>. This is for internal Seerlinq people
                for full access to the DB.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 4</strong> | <em>full</em>
              </td>
              <td>
                <strong>admin</strong>
              </td>
              <td>
                God of the API and DB. Can delete any data. Only admin can
                create new users.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
    </div>
  );
}
