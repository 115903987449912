import { Show } from "solid-js";
import { AddMedication } from "../../../dataAdd/AddMedication";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellSelect } from "../../uiKit/addTable/CellSelect";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddMedicationsTable(props: { model: AddMedication }) {
  return (
    <AddTable
      setAllDates="date"
      columns={[
        {
          header: "Group",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              attr="medication_group"
              item={item}
              values={Object.keys(props.model.medicationOptions)}
              listTitle="Select group"
              onChange={() => {
                item.medication_name = null;
              }}
            />
          ),
        },
        {
          header: "Name",
          mandatory: true,
          cell: (item) => (
            <>
              <CellSelect
                attr="medication_name"
                item={item}
                values={{
                  ...arrayToObject(
                    props.model.medicationOptions[item.medication_group] ?? [],
                  ),
                  ...(props.model.allowFreeText.includes(item.medication_group)
                    ? { [props.model.freeTextPlaceHolder]: "Other..." }
                    : {}),
                }}
                listTitle="Select name"
              />
              <Show
                when={item.medication_name == props.model.freeTextPlaceHolder}
              >
                <CellText attr="freeTextValue" item={item} width={75} />
              </Show>
            </>
          ),
        },
        {
          header: "Started",
          cell: (item) => (
            <CellDate attr="medication_started" item={item} type="date" />
          ),
        },
        {
          header: "Ended",
          cell: (item) => (
            <CellDate attr="medication_ended" item={item} type="date" />
          ),
        },
        {
          header: "Dose",
          mandatory: true,
          cell: (item) => (
            <CellText
              attr="medication_dose"
              item={item}
              width={50}
              disabled={props.model.disabledDoseUnit(item)}
            />
          ),
        },
        {
          header: "Unit",
          mandatory: true,
          cell: (item) => (
            <CellText
              attr="medication_unit"
              item={item}
              width={50}
              disabled={props.model.disabledDoseUnit(item)}
            />
          ),
        },
        {
          header: "Dosage",
          mandatory: true,
          cell: (item) => (
            <>
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[0]}
                onChange={(event) => {
                  item.medication_dosage[0] = event.target.value;
                }}
                style={{ width: "30px", "margin-right": "1px" }}
              />
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[1]}
                onChange={(event) => {
                  item.medication_dosage[1] = event.target.value;
                }}
                style={{ width: "30px", "margin-right": "1px" }}
              />
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[2]}
                onChange={(event) => {
                  item.medication_dosage[2] = event.target.value;
                }}
                style={{ width: "30px", "margin-right": "1px" }}
              />
              <input
                class="number-input"
                type="number"
                min="0"
                step="any"
                value={item.medication_dosage[3]}
                onChange={(event) => {
                  item.medication_dosage[3] = event.target.value;
                }}
                style={{ width: "30px" }}
              />
            </>
          ),
        },
        {
          header: "Frequency",
          cell: (item) => (
            <CellText
              attr="medication_frequency_regular_repeat_every"
              item={item}
              type="number"
            />
          ),
        },
        {
          header: "Comment",
          cell: (item) => <CellText attr="comment" item={item} />,
        },
        {
          header: "Remove row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
      model={props.model}
    />
  );
}

function arrayToObject(arr: string[]): Record<string, string> {
  const res: Record<string, string> = {};

  for (const item of arr) {
    res[item] = item;
  }

  return res;
}
