import { createSignal, For, Show } from "solid-js";

export function TagsField(props: { attr: string; model: Record<string, any> }) {
  const [listAdd, setListAdd] = createSignal(false);

  return (
    <>
      <For each={props.model?.[props.attr] ?? []}>
        {(value) => (
          <span
            class="tag editable"
            onClick={() => {
              props.model[props.attr] = props.model[props.attr].filter(
                (v) => v !== value,
              );
            }}
          >
            {value}
          </span>
        )}
      </For>{" "}
      <Show when={!listAdd()}>
        <span
          class="tag add"
          onClick={() => {
            setListAdd(true);
          }}
        >
          add
        </span>
      </Show>
      <Show when={listAdd()}>
        <input
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              props.model[props.attr].push(event.currentTarget.value);
              setListAdd(false);
            }
          }}
        />{" "}
        <span
          class="tag add"
          onClick={() => {
            setListAdd(false);
          }}
        >
          X
        </span>
      </Show>
    </>
  );
}
