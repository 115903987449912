import clsx from "clsx";
import { createMemo, JSX, Show } from "solid-js";
import { AdminTabId, App } from "../../main";
import { Tab } from "../uiKit/tabs/Tab";
import { Tabs } from "../uiKit/tabs/Tabs";

export function AdminPage(props: { app: App; children?: JSX.Element }) {
  if (!props.app.adminShit) {
    const tabId = new URL(window.location.href).pathname.split("/")[2];
    props.app.initAdmin(tabId as AdminTabId);
  }

  const tabId = createMemo(() => props.app.activeAdminModal);

  const openTab = (tabId: AdminTabId) => {
    props.app.adminNavigate(tabId);
  };

  return (
    <>
      <h2>CLV management</h2>

      <div class="modal-container">
        <Tabs>
          <Tab
            active={tabId() === "clinics"}
            onClick={() => openTab("clinics")}
          >
            Clinics
          </Tab>

          <Tab
            active={tabId() === "physicians"}
            onClick={() => openTab("physicians")}
          >
            Physicians
          </Tab>

          <Tab active={tabId() === "teams"} onClick={() => openTab("teams")}>
            Monitoring teams
          </Tab>

          <Tab active={tabId() === "users"} onClick={() => openTab("users")}>
            Users
          </Tab>

          <Tab
            active={tabId() === "limiters"}
            onClick={() => openTab("limiters")}
          >
            Access limiters
          </Tab>
        </Tabs>

        <Show when={!props.app.adminTabToggling}>
          <div class={clsx("modal-content", tabId() && "active")}>
            {props.children}
          </div>
        </Show>
      </div>
    </>
  );
}
