import { createSignal } from "solid-js";
import { App } from "../main";

export function LoginPage(props: { app: App }) {
  const [username, setUsername] = createSignal("");
  const [password, setPassword] = createSignal("");

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        props.app.seerlinqApi.login(username(), password());
      }}
    >
      <label>Username:</label>{" "}
      <input
        type="text"
        value={username()}
        onChange={(event) => {
          setUsername(event.target.value);
        }}
      />
      <br />
      <br />
      <label>Password:</label>{" "}
      <input
        type="password"
        value={password()}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />
      <br />
      <br />
      <button type="submit">Log In</button>
    </form>
  );
}
