import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../../../apiConnector";
import { diagConfidence } from "../../../constants";
import { AddDiags } from "../../../dataAdd/AddDiags";
import { App } from "../../../main";
import { Patient } from "../../../patient";
import { getObjectFromArray } from "../../../utils";
import { CellDateEditable } from "../../uiKit/table/CellDateEditable";
import { CellEditButtons } from "../../uiKit/table/CellEditButtons";
import { CellSelectEditable } from "../../uiKit/table/CellSelectEditable";
import { CellTextEditable } from "../../uiKit/table/CellTextEditable";
import { HeaderSort } from "../../uiKit/table/HeaderSort";
import { Table } from "../../uiKit/table/Table";
import { AddDiagsTable } from "../add/AddDiagsTable";

export function DiagnosesTable(props: {
  patient: Patient;
  api: ApiConnector;
  app: App;
}) {
  const model = createMemo(() => props.patient.diags);

  return (
    <div>
      <h3>Comorbidities:</h3>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "diags");
        }}
      >
        Add comorbidities
      </button>

      <Show when={props.app.addingActive && !props.app.togglingDataAdd}>
        <AddDiagsTable
          model={props.app.dataAdd as AddDiags}
          patient={props.patient}
        />
      </Show>

      <br />
      <br />
      <br />

      <Table
        model={model()}
        legendExtra={<span>(Dates are in UTC)</span>}
        columns={[
          {
            header1: (
              <HeaderSort
                model={model()}
                attr="diagnosed_at"
                name="Diagnosed"
              />
            ),
            cell: (item, index) => (
              <CellDateEditable
                attr="diagnosed_at"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="diagnosis_name" name="Name" />
            ),
            cell: (item) => item.diagnosis_name,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellSelectEditable
                attr="diagnosis_value"
                index={index}
                model={model()}
                values={getObjectFromArray(
                  props.patient.diagOptions[item.diagnosis_name],
                )}
              />
            ),
          },
          {
            header1: "Confidence",
            cell: (item, index) => (
              <CellSelectEditable
                attr="diagnosis_confidence"
                index={index}
                model={model()}
                values={getObjectFromArray(diagConfidence)}
              />
            ),
          },
          {
            header1: "Remission",
            cell: (item, index) => (
              <CellDateEditable
                attr="remission"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem("diagnoses", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.amIAdmin && {
            header1: "Delete",
            cell: (item) => (
              <button
                onClick={() => props.patient.deleteItem("diagnoses", item.uuid)}
              >
                Delete
              </button>
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </div>
  );
}
