import { ppgRelatedFlag } from "../enumToText";
import { parseDatetimeToLocal } from "../utils";
import { MedData } from "./MedData";

export class MergedMedData extends MedData {
  originFieldName: string = "data_source";
  flagKey: string = "seerlinq_measurement_quality_flag";
  tagsKey: string = "tags";
  condsKey: string = "ppg_conditions";

  medDataKeys: string[] = [
    "measurement_datetime",
    "measurement_type",
    "measurement_value",
    "measurement_unit",
    "comment",
    "created_at",
    "update_history",
    "uuid",
    this.flagKey,
    this.tagsKey,
    this.condsKey,
  ];

  editableField = {
    "Patient / Physician": this.editable,
    "SQ-algo": ["seerlinq_measurement_quality_flag", "comment"],
    "PPG-derived": ["comment"],
  };
  updateEndpoint = {
    "Patient / Physician": "medicaldata",
    "SQ-algo": "computed",
    "PPG-derived": "derived",
  };

  constructor(medData, canShowHistory = false) {
    super(medData, canShowHistory);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.initSort = [true];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = [
      this.originFieldName,
      this.flagKey,
      "measurement_type",
      this.condsKey,
    ];
    this.filteringListAttrs = [this.tagsKey];
  }

  mergeWithPPGDerivedAndComputed(
    derivedDataList: any[],
    computedDataList: any[],
    keyToRename: string,
    valueMapping: object,
  ) {
    const filterAndLabel = (
      list: any[],
      label: string,
      renameKey: boolean = true,
      defaultQF: number = null,
      defaultTag: string[] = null,
      defaultCond: string = null,
    ) =>
      list.map((item) => {
        const filteredItem = this.medDataKeys.reduce((acc, key) => {
          if (item.hasOwnProperty(key)) {
            acc[key] =
              renameKey &&
              key === keyToRename &&
              valueMapping[item[key]] !== undefined
                ? valueMapping[item[key]]
                : item[key];
          }
          return acc;
        }, {});
        filteredItem[this.originFieldName] = label;
        if (defaultQF !== null) {
          filteredItem[this.flagKey] = defaultQF;
        }
        if (defaultTag !== null) {
          filteredItem[this.tagsKey] = defaultTag;
        }
        if (defaultCond !== null) {
          filteredItem[this.condsKey] = defaultCond;
        }

        return filteredItem;
      });
    const medData = filterAndLabel(
      this.data,
      "Patient / Physician",
      false,
      -1,
      ["Not tagged"],
      "--",
    );
    const derivedData = derivedDataList
      .map((item) => filterAndLabel(item.data, "PPG-derived", true))
      .flat();
    const computedData = computedDataList
      .map((item) => filterAndLabel(item.data, "SQ-algo", true))
      .flat();
    this.data = [...medData, ...derivedData, ...computedData];
  }

  flag(member: any) {
    return ppgRelatedFlag[member[this.flagKey]] || "N/A";
  }

  canEdit(fieldName: string, member: object) {
    const source = member[this.originFieldName];
    if (this.editableField[source].indexOf(fieldName) > -1) {
      return true;
    }
    return false;
  }

  getEditedField(index: number) {
    const member = this.paginatedData[index];
    return this.getEditedSource(index, member.data_source);
  }

  getEditedSource(index: number, source: string) {
    const paginatedStart = (this.currentPage - 1) * this.rowsPerPage;
    const member = this.rawData[paginatedStart + index];
    var editedField = Object.keys(member)
      .filter(
        (key) =>
          this.editableField[source].length === 0 ||
          this.editableField[source].includes(key),
      )
      .reduce((obj, key) => {
        obj[key] = member[key];
        return obj;
      }, {});
    for (const key of this.dateTimeFields) {
      if (editedField.hasOwnProperty(key)) {
        const value = editedField[key];
        editedField[key] = value ? parseDatetimeToLocal(value) : null;
      }
    }
    return editedField;
  }
}
