import { createMemo, JSX, Show } from "solid-js";
import { DataModel } from "../../../dataModels/DataModel";

export function CellChoose(props: {
  model: DataModel;
  index: number;
  valueView: JSX.Element;
  valueEdit: JSX.Element;
  onClick: () => void;
  disabled?: boolean;
}) {
  const editing = createMemo(() => props.model.editingId === props.index);

  return (
    <>
      <Show when={editing()}>
        <span>{props.valueEdit}</span>
        <br />
        <Show when={!props.disabled}>
          <button onClick={props.onClick}>Choose</button>
        </Show>
      </Show>

      <Show when={!editing()}>{props.valueView}</Show>
    </>
  );
}
