export function DateField(props: {
  model: Record<string, any>;
  attr: string;
  type: "datetime-local" | "date";
}) {
  return (
    <input
      type={props.type}
      step="1"
      value={props.model[props.attr]}
      onChange={(event) => {
        props.model[props.attr] = event.target.value;
      }}
    />
  );
}
