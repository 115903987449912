import { createMemo, Show } from "solid-js";
import { AdminConsole } from "../../adminUtils";
import { AddPhysician } from "../../dataAdd/AddPhysician";
import { seerlinqRealms } from "../../enumToText";
import { App } from "../../main";
import { ClinicsModal } from "../modals/ClinicsModal";
import { PatientsModal } from "../modals/PatientsModal";
import { UsersModal } from "../modals/UsersModal";
import { ChooseField } from "../uiKit/form/ChooseField";
import { FormRow } from "../uiKit/form/FormRow";
import { SelectField } from "../uiKit/form/SelectField";
import { TagsField } from "../uiKit/form/TagsField";
import { TextField } from "../uiKit/form/TextField";
import { CellChoose } from "../uiKit/table/CellChoose";
import { CellEditButtons } from "../uiKit/table/CellEditButtons";
import { CellSelectEditable } from "../uiKit/table/CellSelectEditable";
import { CellTagsEditable } from "../uiKit/table/CellTagsEditable";
import { CellTextEditable } from "../uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "../uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "../uiKit/table/HeaderSort";
import { Table } from "../uiKit/table/Table";

export function PhysiciansAdmin(props: { admin: AdminConsole; app: App }) {
  const model = createMemo(() => props.admin.physicians);
  const dataAdd = createMemo(() => props.app.dataAdd as AddPhysician);

  return (
    <div id="modal-content">
      <ClinicsModal modal={props.admin.clinicsModal} />

      <PatientsModal modal={props.admin.patientsModal} />

      <UsersModal modal={props.admin.usersModal} />

      <h2>Physicians</h2>
      <span>
        We have <strong>{props.admin.physicians.data.length}</strong>{" "}
        physicians(s) in the database.
      </span>

      <br />
      <br />

      <button
        onClick={() => {
          props.admin.toggleCreatePhysician();
        }}
      >
        {props.admin.addingPhysician ? "Cancel" : "Create new physician"}
      </button>

      <br />
      <br />

      <Show when={!props.admin.addingPhysician}>
        <Table
          columns={[
            {
              header1: "Surname",
              header2: <HeaderSort attr="surname" model={model()} />,
              cell: (item, index) => (
                <CellTextEditable
                  attr="surname"
                  index={index}
                  model={model()}
                  width={90}
                  displayValue={<strong>{item.surname}</strong>}
                />
              ),
            },
            {
              header1: "Name",
              cell: (item, index) => (
                <CellTextEditable
                  attr="given_name"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Titles",
              cell: (item, index) => (
                <CellTextEditable
                  attr="titles"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Realm",
              header2: (
                <HeaderSelectFilter
                  attr="realm"
                  model={model()}
                  name="Realm"
                  values={seerlinqRealms}
                />
              ),
              cell: (item, index) => (
                <CellSelectEditable
                  attr="realm"
                  index={index}
                  model={model()}
                  values={seerlinqRealms}
                />
              ),
            },
            {
              header1: "Email",
              cell: (item, index) => (
                <CellTextEditable
                  attr="email"
                  index={index}
                  model={model()}
                  width={90}
                />
              ),
            },
            {
              header1: "Phone",
              cell: (item, index) => (
                <CellTagsEditable attr="phone" index={index} model={model()} />
              ),
            },
            {
              header1: "User",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  onClick={() => {
                    props.admin.editConnectedUserForPhysician(index);
                  }}
                  valueEdit={props.admin.currentConnectedUsernameForPhysician(
                    index,
                  )}
                  valueView={item.username}
                />
              ),
            },
            {
              header1: "# of patients",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  onClick={() => {
                    props.admin.editPatientsForPhysician(index);
                  }}
                  valueEdit={props.admin.currentPatientsForPhysician(index)}
                  valueView={item.numPatients}
                />
              ),
            },
            {
              header1: "# of clinics",
              cell: (item, index) => (
                <CellChoose
                  index={index}
                  model={model()}
                  onClick={() => {
                    props.admin.editClinicsForPhysician(index);
                  }}
                  valueEdit={props.admin.currentClinicsForPhysician(index)}
                  valueView={item.numClinics}
                />
              ),
            },
            {
              header1: "Edit",
              cell: (item, index) => (
                <CellEditButtons
                  index={index}
                  model={model()}
                  onConfirm={(editedField) => {
                    props.admin.updatePhysician(item.uuid, editedField);
                  }}
                  onCleanup={() => {
                    props.admin.resetModals();
                  }}
                />
              ),
            },
          ]}
          model={model()}
        />
      </Show>

      <Show
        when={
          props.admin.addingPhysician &&
          props.app.dataInited &&
          dataAdd() instanceof AddPhysician
        }
      >
        <div>
          <h3>Add new physician</h3>
          <form>
            <table class="invisible-table invisible-table-data-add">
              <FormRow
                label="Surname"
                data={<TextField attr="surname" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Given name(s)"
                data={
                  <TextField attr="given_name" model={dataAdd().addingList} />
                }
                mandatory
              />

              <FormRow
                label="Titles"
                data={<TextField attr="titles" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Monitoring realm"
                data={
                  <SelectField
                    attr="realm"
                    model={dataAdd().addingList}
                    values={seerlinqRealms}
                    intValue
                  />
                }
                mandatory
              />

              <FormRow
                label="Email"
                data={<TextField attr="email" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Phone(s)"
                data={<TagsField attr="phone" model={dataAdd().addingList} />}
                mandatory
              />

              <FormRow
                label="Connected user"
                data={
                  <ChooseField
                    value={
                      props.admin.usersModal.finalSingleChoice
                        ? props.admin.users.userMapping[
                            props.admin.usersModal.finalSingleChoice
                          ]
                        : "None"
                    }
                    onClick={() => {
                      props.admin.chooseConnectedUserForPhysician();
                    }}
                  />
                }
              />

              <FormRow
                label="Physician clinics"
                data={
                  <ChooseField
                    value={`${props.admin.clinicsModal.finalMultiChoice.length} chosen`}
                    onClick={() => {
                      props.admin.chooseClinicsForPhysician(
                        dataAdd().addingList.realm,
                      );
                    }}
                  />
                }
              />

              <FormRow
                label="Physician patients"
                data={
                  <ChooseField
                    value={`${props.admin.patientsModal.finalMultiChoice.length} chosen`}
                    onClick={() => {
                      props.admin.choosePatientsForPhysician(
                        dataAdd().addingList.realm,
                      );
                    }}
                  />
                }
              />
            </table>

            <br />
            <br />

            <button
              type="button"
              onClick={() => {
                dataAdd().post(
                  dataAdd().addingList.phone,
                  props.admin.clinicsModal.finalMultiChoice,
                  props.admin.patientsModal.finalMultiChoice,
                  props.admin.usersModal.finalSingleChoice,
                );
              }}
            >
              Add physician
            </button>
          </form>
        </div>
      </Show>
    </div>
  );
}
