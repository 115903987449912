import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../../../apiConnector";
import { AddSymptoms } from "../../../dataAdd/AddSymptoms";
import { App } from "../../../main";
import { Patient } from "../../../patient";
import { CellCheckboxEditable } from "../../uiKit/table/CellCheckboxEditable";
import { CellDateEditable } from "../../uiKit/table/CellDateEditable";
import { CellEditButtons } from "../../uiKit/table/CellEditButtons";
import { CellTextEditable } from "../../uiKit/table/CellTextEditable";
import { HeaderSort } from "../../uiKit/table/HeaderSort";
import { Table } from "../../uiKit/table/Table";
import { AddSymptomsTable } from "../add/AddSymptomsTable";
import { Plot } from "./Plot";

export function SymptomsTable(props: {
  patient: Patient;
  api: ApiConnector;
  app: App;
}) {
  const model = createMemo(() => props.patient.symptoms);

  return (
    <div>
      <h3>Symptoms:</h3>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "symptoms");
        }}
      >
        Add symptoms
      </button>

      <Show when={props.app.addingActive && !props.app.togglingDataAdd}>
        <AddSymptomsTable model={props.app.dataAdd as AddSymptoms} />
      </Show>

      <Plot model={props.patient.symptomPlot} />

      <br />
      <br />
      <br />

      <Table
        model={model()}
        legendExtra={<span>(Dates are in UTC)</span>}
        columns={[
          {
            header1: (
              <HeaderSort model={model()} attr="symptom_date" name="Started" />
            ),
            cell: (item, index) => (
              <CellDateEditable
                attr="symptom_date"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: (
              <HeaderSort model={model()} attr="symptom_name" name="Symptom" />
            ),
            cell: (item) => item.symptom_name,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellTextEditable
                attr="symptom_value"
                index={index}
                model={model()}
                type="number"
              />
            ),
          },
          {
            header1: "Change in last 6 months",
            cell: (item, index) => (
              <CellCheckboxEditable
                attr="symptom_change_in_last_six_m"
                index={index}
                model={model()}
              />
            ),
          },
          {
            header1: "Change date",
            cell: (item, index) => (
              <CellDateEditable
                attr="symptom_change_date"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Value before",
            cell: (item, index) => (
              <CellTextEditable
                attr="symptom_value_before"
                index={index}
                model={model()}
                type="number"
              />
            ),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  props.patient.updateItem("symptoms", item.uuid, editedField);
                }}
              />
            ),
          },
          props.api.amIAdmin && {
            header1: "Delete",
            cell: (item) => (
              <button
                onClick={() => props.patient.deleteItem("symptoms", item.uuid)}
              >
                Delete
              </button>
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </div>
  );
}
