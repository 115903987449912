import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../../../apiConnector";
import { AddMedication } from "../../../dataAdd/AddMedication";
import { App } from "../../../main";
import { Patient } from "../../../patient";
import { CellDateEditable } from "../../uiKit/table/CellDateEditable";
import { CellEditButtons } from "../../uiKit/table/CellEditButtons";
import { CellTextEditable } from "../../uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "../../uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "../../uiKit/table/HeaderSort";
import { Table } from "../../uiKit/table/Table";
import { AddMedicationsTable } from "../add/AddMedicationsTable";

export function MedicationsTable(props: {
  patient: Patient;
  api: ApiConnector;
  app: App;
}) {
  const model = createMemo(() => props.patient.medications);

  return (
    <div>
      <h3>Medications:</h3>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "medications");
        }}
      >
        Add medications
      </button>

      <Show when={props.app.addingActive && !props.app.togglingDataAdd}>
        <AddMedicationsTable model={props.app.dataAdd as AddMedication} />
      </Show>

      <br />
      <br />
      <br />

      <Table
        columns={[
          {
            header1: "Started",
            header2: <HeaderSort attr="medication_started" model={model()} />,
            cell: (item, index) => (
              <CellDateEditable
                attr="medication_started"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Ended",
            header2: <HeaderSort attr="medication_ended" model={model()} />,
            cell: (item, index) => (
              <CellDateEditable
                attr="medication_ended"
                index={index}
                model={model()}
                type="date"
              />
            ),
          },
          {
            header1: "Group",
            header2: (
              <HeaderSelectFilter
                attr="medication_group"
                model={model()}
                name="Group"
              />
            ),
            cell: (item) => item.medication_group,
          },
          {
            header1: "Name",
            header2: <HeaderSort attr="medication_name" model={model()} />,
            cell: (item, index) => (
              <CellTextEditable
                attr="medication_name"
                index={index}
                model={model()}
                width={50}
                disabled={!model().canEditName(item)}
              />
            ),
          },
          {
            header1: "Dose",
            cell: (item, index) => (
              <CellTextEditable
                attr="medication_dose"
                index={index}
                model={model()}
                width={40}
              />
            ),
          },
          {
            header1: "Unit",
            cell: (item, index) => (
              <CellTextEditable
                attr="medication_unit"
                index={index}
                model={model()}
                width={30}
              />
            ),
          },
          {
            header1: "Dosage",
            cell: (item, index) => (
              <Show
                when={model().editingId === index}
                fallback={item.medication_dosage.join("/")}
              >
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[0]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[0] =
                      event.target.value;
                  }}
                />
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[1]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[1] =
                      event.target.value;
                  }}
                />
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[2]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[2] =
                      event.target.value;
                  }}
                />
                <input
                  class="number-input"
                  type="number"
                  min="0"
                  value={model().editedField?.medication_dosage[3]}
                  onChange={(event) => {
                    model().editedField.medication_dosage[3] =
                      event.target.value;
                  }}
                />
              </Show>
            ),
          },
          {
            header1: "Frequency",
            cell: (item, index) => model().freqString(item),
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Change dosage",
            cell: (item, index) => (
              <>
                <Show
                  when={
                    model().editingId === index &&
                    model().editedField &&
                    "medication_change_date" in model().editedField
                  }
                >
                  <input
                    type="date"
                    value={model().editedField?.medication_change_date}
                    onChange={(event) => {
                      model().editedField.medication_change_date =
                        event.target.value;
                    }}
                  />
                </Show>

                <Show when={model().editingId !== index}>
                  <button
                    onClick={() => {
                      model().startChangingDosage(index);
                    }}
                  >
                    Change
                  </button>
                </Show>
              </>
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <CellEditButtons
                index={index}
                model={model()}
                onConfirm={(editedField) => {
                  if ("medication_change_date" in editedField) {
                    props.patient.updateItem(
                      "medications/change",
                      item.uuid,
                      editedField,
                    );
                  } else {
                    props.patient.updateItem(
                      "medications",
                      item.uuid,
                      editedField,
                    );
                  }
                }}
              />
            ),
          },
          props.api.amIAdmin && {
            header1: "Delete",
            cell: (item) => (
              <button
                onClick={() => {
                  props.patient.deleteItem("medications", item.uuid);
                }}
              >
                Delete
              </button>
            ),
          },
          model().showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          model().showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
        model={model()}
      />
    </div>
  );
}
