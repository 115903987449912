import { For } from "solid-js";
import { SimplePlot } from "../../../plotting";

export function Plot(props: { model: SimplePlot }) {
  return (
    <div id="medical-plot">
      <h4>Plot</h4>
      <label>Plot:</label>{" "}
      <select
        value={props.model.plotType}
        onChange={(event) => {
          props.model.plotType = event.target.value;
          props.model.preparePlot();
        }}
      >
        <option value="null" disabled>
          Select type
        </option>
        <For each={props.model.plotTypeOptions}>
          {(type) => <option>{type}</option>}
        </For>
      </select>{" "}
      <label>between</label>{" "}
      <input
        type="datetime-local"
        value={props.model.plotStart}
        onChange={(event) => {
          props.model.plotStart = event.target.value;
          props.model.updatePlot(props.model.plotStart, props.model.plotEnd);
        }}
      />{" "}
      -{" "}
      <input
        type="datetime-local"
        value={props.model.plotEnd}
        onChange={(event) => {
          props.model.plotEnd = event.target.value;
          props.model.updatePlot(props.model.plotStart, props.model.plotEnd);
        }}
      />
      <br />
      <canvas
        class="plot"
        id={props.model.chartId}
        style={{ display: props.model.chart ? undefined : "none" }}
      />
    </div>
  );
}
