import { createMemo, Show } from "solid-js";
import { ApiConnector } from "../../../apiConnector";
import { AddVitals } from "../../../dataAdd/AddVitals";
import { ppgRelatedFlag } from "../../../enumToText";
import type { App } from "../../../main";
import { Patient } from "../../../patient";
import { floatRounding } from "../../../utils";
import { CellDateEditable } from "../../uiKit/table/CellDateEditable";
import { CellEditButtons } from "../../uiKit/table/CellEditButtons";
import { CellSelectEditable } from "../../uiKit/table/CellSelectEditable";
import { CellTags } from "../../uiKit/table/CellTags";
import { CellTextEditable } from "../../uiKit/table/CellTextEditable";
import { HeaderSelectFilter } from "../../uiKit/table/HeaderSelectFilter";
import { HeaderSort } from "../../uiKit/table/HeaderSort";
import { Table } from "../../uiKit/table/Table";
import { AddMedicalTable } from "../add/AddMedicalTable";
import { Plot } from "./Plot";

export function VitalsTable(props: {
  patient: Patient;
  api: ApiConnector;
  app: App;
}) {
  const model = createMemo(() => props.patient.vitals);

  return (
    <div>
      <h3>Vitals:</h3>
      <button
        onClick={() => {
          props.app.toggleDataAddSection(undefined, "vitals");
        }}
      >
        Add vitals
      </button>

      <Show when={props.app.addingActive && !props.app.togglingDataAdd}>
        <AddMedicalTable model={props.app.dataAdd as AddVitals} />
      </Show>

      <Plot model={props.patient.vitalsPlot} />

      <br />
      <br />
      <br />

      <Table
        model={model()}
        columns={[
          {
            header1: "Measured",
            header2: <HeaderSort model={model()} attr="measurement_datetime" />,
            cell: (item, index) => (
              <CellDateEditable
                model={model()}
                index={index}
                attr="measurement_datetime"
                type="datetime-local"
              />
            ),
          },
          {
            header1: "Source",
            header2: (
              <HeaderSelectFilter
                attr="data_source"
                model={model()}
                name="Source"
              />
            ),
            cell: (item) => item.data_source,
          },
          {
            header1: "Type",
            header2: (
              <HeaderSelectFilter
                attr="measurement_type"
                model={model()}
                name="Type"
              />
            ),
            cell: (item) => item.measurement_type,
          },
          {
            header1: "Value",
            cell: (item, index) => (
              <CellTextEditable
                attr="measurement_value"
                index={index}
                model={model()}
                displayValue={floatRounding(item.measurement_value, 1)}
                width={50}
              />
            ),
          },
          {
            header1: "Unit",
            cell: (item) => item.measurement_unit,
          },
          {
            header1: "PPG condition",
            header2: (
              <HeaderSelectFilter
                attr="ppg_conditions"
                model={model()}
                name="Condition"
              />
            ),
            cell: (item) => item.ppg_conditions,
          },
          {
            header1: "Quality Flag",
            header2: (
              <HeaderSelectFilter
                attr="seerlinq_measurement_quality_flag"
                model={model()}
                name="Flag"
                values={ppgRelatedFlag}
              />
            ),
            cell: (item, index) => (
              <CellSelectEditable
                attr="seerlinq_measurement_quality_flag"
                index={index}
                model={model()}
                values={ppgRelatedFlag}
              />
            ),
          },
          {
            header1: "Tags",
            header2: (
              <HeaderSelectFilter attr="tags" model={model()} name="Tags" />
            ),
            cell: (item) => <CellTags tags={item.tags} />,
          },
          {
            header1: "Comment",
            cell: (item, index) => (
              <CellTextEditable
                attr="comment"
                index={index}
                model={model()}
                width={50}
              />
            ),
          },
          {
            header1: "Edit",
            cell: (item, index) => (
              <>
                <CellEditButtons
                  model={model()}
                  index={index}
                  onConfirm={(editedField) => {
                    props.patient.updateItem(
                      model().updateEndpoint[item.data_source],
                      item.uuid,
                      editedField,
                      model().dateTimeFields,
                    );
                  }}
                />
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
              </>
            ),
          },
          props.api.amIAdmin && {
            header1: "Delete",
            cell: (item) => (
              <>
                <Show
                  when={
                    !model().notEditable.includes(item.measurement_type) &&
                    item.data_source == "Patient / Physician"
                  }
                >
                  <button
                    onClick={() => {
                      props.patient.deleteItem("medicaldata", item.uuid);
                    }}
                  >
                    Delete
                  </button>
                </Show>
                <Show
                  when={model().notEditable.includes(item.measurement_type)}
                >
                  &nbsp;(auto-computed)
                </Show>
                <Show when={item.data_source !== "Patient / Physician"}>
                  &nbsp;(cannot delete)
                </Show>
              </>
            ),
          },
          props.patient.vitals.showingHistory && {
            header1: "Added",
            cell: (item) => item.created_at,
          },
          props.patient.vitals.showingHistory && {
            header1: "Last edit",
            cell: (item) => item.lastUpdate,
          },
        ]}
      />
    </div>
  );
}
