import { createMemo, For, JSX, Show } from "solid-js";
import { DataAdd } from "../../../dataAdd/DataAdd";

export interface Column<T> {
  header: JSX.Element;
  cell: (item: T, index: number) => JSX.Element;
  mandatory?: boolean;
}

export function AddTable(props: {
  model: DataAdd;
  columns: Column<Record<string, any>>[];
  setAllDates?: "date" | "datetime";
  canAddRows?: boolean;
  infoExtra?: JSX.Element;
  footer?: JSX.Element;
}) {
  const hasMandatory = createMemo(() => props.columns.some((c) => c.mandatory));

  return (
    <div>
      <hr />
      <div>
        <h3>{props.model.desc}</h3>
        <Show when={hasMandatory()}>
          (
          <span style="color: #bb16a3">
            <strong>*</strong>
          </span>{" "}
          = mandatory)
        </Show>
        <form>
          {props.infoExtra}

          <Show when={props.setAllDates}>
            <div class="datetime-setter">
              <p class="title">
                <strong>
                  <Show when={props.setAllDates === "datetime"}>
                    Set all datetimes:
                  </Show>
                  <Show when={props.setAllDates === "date"}>
                    Set all dates:
                  </Show>
                </strong>
              </p>
              <p>
                (Default) <span>{props.model.defaultDateTimeName}</span>
                <button
                  type="button"
                  onClick={() => {
                    props.model.setAllDatetimes(
                      props.model.defaultDateTime,
                      props.setAllDates === "date",
                    );
                  }}
                >
                  Set
                </button>
              </p>
              <p>
                <input
                  type={
                    props.setAllDates === "date" ? "date" : "datetime-local"
                  }
                  step="1"
                  value={props.model.customDateTime}
                  onChange={(event) => {
                    props.model.customDateTime = event.target.value;
                  }}
                />
                <button
                  type="button"
                  disabled={!props.model.customDateTime}
                  onClick={() => {
                    props.model.setAllDatetimes(
                      props.model.customDateTime,
                      props.setAllDates === "date",
                    );
                  }}
                >
                  Set
                </button>
              </p>
            </div>
          </Show>

          <div class="table-container">
            <table>
              <thead>
                {/* header */}
                <tr>
                  <For each={props.columns}>
                    {(col) => (
                      <th class="col_heading">
                        {col.header}

                        <Show when={col.mandatory}>
                          {" "}
                          <span style="color: #bb16a3">
                            <strong>*</strong>
                          </span>
                        </Show>
                      </th>
                    )}
                  </For>
                </tr>
              </thead>

              <tbody>
                {/* rows */}
                <For each={props.model.addingList.filter(Boolean)}>
                  {(item, index) => (
                    <tr>
                      <For each={props.columns}>
                        {(col) => <td>{col.cell(item, index())}</td>}
                      </For>
                    </tr>
                  )}
                </For>
              </tbody>
            </table>
          </div>

          <Show when={props.canAddRows !== false}>
            <button
              type="button"
              onClick={() => {
                props.model.addItem();
              }}
            >
              + Add Row
            </button>
          </Show>

          {props.footer && (
            <>
              <br />
              {props.footer}
            </>
          )}

          <br />
          <br />

          <button
            type="button"
            onClick={() => {
              props.model.postBulk();
            }}
          >
            Post
          </button>
        </form>
      </div>
      <hr />
    </div>
  );
}
