import { AddBasics } from "../../../dataAdd/AddBasics";
import { AddLabs } from "../../../dataAdd/AddLabs";
import { AddVitals } from "../../../dataAdd/AddVitals";
import { AddTable } from "../../uiKit/addTable/AddTable";
import { CellDate } from "../../uiKit/addTable/CellDate";
import { CellRemoveButton } from "../../uiKit/addTable/CellRemoveButton";
import { CellSelect } from "../../uiKit/addTable/CellSelect";
import { CellText } from "../../uiKit/addTable/CellText";

export function AddMedicalTable(props: {
  model: AddVitals | AddBasics | AddLabs;
}) {
  return (
    <AddTable
      model={props.model}
      setAllDates="datetime"
      columns={[
        {
          header: "Measured at",
          mandatory: true,
          cell: (item) => (
            <CellDate
              item={item}
              attr="measurement_datetime"
              type="datetime-local"
            />
          ),
        },
        {
          header: "Type",
          mandatory: true,
          cell: (item) => (
            <CellSelect
              item={item}
              attr="measurement_type"
              values={props.model.variables}
              onChange={(newValue) => {
                item.measurement_unit = props.model.getUnits(newValue, true);
              }}
            />
          ),
        },
        {
          header: "Value",
          mandatory: true,
          cell: (item) => <CellText item={item} attr="measurement_value" />,
        },
        {
          header: "Unit",
          cell: (item) => (
            <CellSelect
              item={item}
              attr="measurement_unit"
              values={props.model.getUnits(item.measurement_type)}
              disabled={props.model.getUnits(item.measurement_type).length <= 1}
            />
          ),
        },
        {
          header: "Comment",
          cell: (item) => <CellText item={item} attr="comment" />,
        },
        {
          header: "Remove data row",
          cell: (item, index) => (
            <CellRemoveButton index={index} model={props.model} />
          ),
        },
      ]}
    />
  );
}
