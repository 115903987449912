import { Show } from "solid-js";

export function TextField(props: {
  model: Record<string, any>;
  type?: "text" | "password";
  attr: string;
  width?: number;
  multiline?: boolean;
}) {
  return (
    <>
      <Show when={!props.multiline}>
        <input
          type={props.type ?? "text"}
          value={props.model[props.attr] ?? ""}
          onChange={(event) => {
            props.model[props.attr] = event.target.value;
          }}
          style={{ width: props.width ? `${props.width}px` : undefined }}
        />
      </Show>

      <Show when={props.multiline}>
        <textarea
          value={props.model[props.attr]}
          onChange={(event) => {
            props.model[props.attr] = event.target.value;
          }}
        />
      </Show>
    </>
  );
}
