import * as Sentry from "@sentry/browser";
import { envName } from "./config";

export function initSentry() {
  const rateLimiter = new SlidingWindowRateLimiter({
    maxRequestsPerWindow: 3,
    windowDurationMs: 10_000,
  });

  try {
    Sentry.init({
      dsn: "https://273c38a86e9eaec1b5b4f1edb66dfb44@o4508094333190144.ingest.de.sentry.io/4508094336008272",
      environment: envName,
      beforeSend: (event) => {
        if (!rateLimiter.trackRequest()) {
          console.error("[Sentry] Error rate limited", event);
          return null;
        }

        return event;
      },
    });
  } catch (error) {
    console.error("Failed to init Sentry", error);
  }
}

class SlidingWindowRateLimiter {
  private requestTimestamps: number[] = [];

  constructor(
    private config: { maxRequestsPerWindow: number; windowDurationMs: number },
  ) {}

  trackRequest(): boolean {
    const currentTime = Date.now();

    // Remove timestamps that are outside of the sliding window
    while (
      this.requestTimestamps.length > 0 &&
      this.requestTimestamps[0] <= currentTime - this.config.windowDurationMs
    ) {
      this.requestTimestamps.shift();
    }

    if (this.requestTimestamps.length >= this.config.maxRequestsPerWindow) {
      return false; // Rate limit exceeded
    }

    // Log the timestamp of the current request
    this.requestTimestamps.push(Date.now());
    return true; // Request allowed
  }
}
