import { apiGetUsers, apiGetUsersPhysicians } from "../api";
import { ApiConnector } from "../apiConnector";
import { APIDataModel } from "./APIDataModel";

export class Users extends APIDataModel {
  userMapping = {};

  constructor(public api: ApiConnector) {
    super(api, null);
    this.sortBy = ["username", "created_at"];
    this.sortByNames = ["Username", "Added"];
    this.dateTimeFields = ["created_at", "last_login"];
    this.stringFields = ["username"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = ["role", "active", "monitoring_team", "added_by"];
    this.editable = [
      "connected_patient_id",
      "preferred_language",
      "managed_patients",
    ];
    this.defaultFilter = {
      role: [
        "admin",
        "seerlinq-user",
        "study-physician",
        "physician",
        "patient",
      ],
    };
    this.queryKey = "username";

    this.paginated = false;
  }

  dataInit() {
    this.data = this.data.map((item) => {
      if (item.monitoring_team != null) {
        var monTeam = item.monitoring_team.display_name;
      } else {
        var monTeam = null;
      }
      return { ...item, ["monitoring_team"]: monTeam };
    });
    super.init();
    this.getMapping();
  }

  async init() {
    const users = await apiGetUsers({ throwOnError: true });
    this.data = users.data.users;
    this.dataInit();
    this.firstPage = true;
    this.fullyLoaded = true;
  }

  async initPhysiciansOnly() {
    const users = await apiGetUsersPhysicians({ throwOnError: true });

    this.data = users.data.users;
    this.dataInit();
    this.firstPage = true;
    this.fullyLoaded = true;
  }

  getMapping() {
    for (const user of this.data) {
      this.userMapping[user.uuid] = user.username;
    }
  }

  generateLinks(ids: number[]) {
    return ids
      .map(
        (id) =>
          `<strong><a style="color: #bb16a3" href="/patient/${id}">${id}</a></strong>`,
      )
      .join(", ");
  }
}
